import React from "react";
import parse from "html-react-parser";
import commands from "../../commands";
import "./css/index.scoped.css";
import { Container, Button, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SingleOfferBanner() {
  const mybanner = commands.cache.getWebPositionedBanners({ position: 3 });

  const history = useHistory();
  return (
    <div
      className={
        mybanner[0]?.images[0]?.url
          ? "yt-single-offer-component hoverMe"
          : "yt-single-offer-component"
      }
      onClick={() =>
        { mybanner[0]?.images[0]?.url && (window.location.href = mybanner[0]?.images[0]?.url)}
      }
    >
      <LazyLoadImage
        className="img-fluid d-none d-sm-block"
        alt="bigbargains"
        effect="blur"
        width="100%"
        src={mybanner[0]?.images[0]?.original_image}
      />
      
    </div>
  );
}

export default SingleOfferBanner;
