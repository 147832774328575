import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "reactstrap";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { RiHeadphoneLine, RiHome5Line } from "react-icons/ri";

import commands from "../../commands";
import SuccessBlock from "../success-block";
import { CgSpinner } from "react-icons/cg";
import langg from "../../language";
import AuthState from "../../redux/states/auth";
import * as validationSchemas from "../../validationSchemas";

import "./css/index.scoped.css";

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

function ContactForm() {
  const [values, setValues] = useState();
  const [messageSent, setMessageSent] = useState(false);
  const authState = AuthState.get();
  const lang = new langg("contactUsScreen");

  let initialData = { name: "", email: "", phone: "", title: "", message: "" };

  if (commands.user.isLoggedIn()) {
    initialData = {
      name: authState.user?.name || "",
      email: authState.user?.email || "",
      phone: authState.user?.phone_number || "",
      title: "",
      message: "",
    };
  }

  async function sendMessage(values) {
    const sent = await commands.user.sendContactMessage(values);

    setMessageSent(sent);
    setValues(undefined);
  }

  useEffect(() => {
    if (values != undefined) {
      sendMessage(values);
    }
  }, [values]);

  const sending = !!values;
  return !messageSent ? (
    <section className="contactform yt-main-contact-us-pg">
      <Container>
        <div className="yt-cm-mobile-bread">
          <div className="pageroute profile-pg-breadcrumbs">
            <span className="profile-pg-home">Home {">"}</span>{" "}
            <span className="">Contact Us</span>
          </div>
        </div>
        <div className="yt-main-wrapper">
          <div className="title">{lang.get("contactUs", "Contact us")}</div>

          <div className="supportnumber">
            <RiHeadphoneLine className="supportimage mr-2" />
            <span className="number">
              CALL US : <a href="tel:1800 419 9555">1800 419 9555</a>
            </span>
            <div>
              <div
                className="yt-footer-widget-title"
                style={{ lineHeight: "26px" }}
              >
                <RiHome5Line className="supportimage mr-2" />
                BIGBARGAINS E COMMERCE SOLUTIONS PRIVATE LIMITED
              </div>
              <div
                className="yt-ftr-link w3-ripple"
                style={{ lineHeight: "20px", paddingLeft: "31px" }}
              >
                Address : # No. 30, “PRAGATHI MANSION” 2nd Floor Dr. DVG Road,
                Basavanagudi, Bangalore -560 004. Office Phone NO: <a href="tel:08042190504">080-42190504</a>
              </div>
            </div>
          </div>

          <Formik
            initialValues={initialData}
            onSubmit={(values) => setValues(values)}
            validationSchema={validationSchemas.contactForm}
          >
            {({
              values,
              setValues,
              handleChange,
              errors,
              setFieldTouched,
              touched,
              handleSubmit,
            }) => {
              const cacheState = useSelector((state) => state.cache);

              useEffect(() => {
                setValues(values);
              }, [cacheState?.language]);

              return (
                <Fragment>
                  <Form>
                    <div className="form group contact-border">
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="group">
                            <input
                              type="text"
                              required
                              name={"name"}
                              onChange={handleChange}
                              onBlur={() => setFieldTouched("name")}
                              value={values.name}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label>{lang.get("name", "Name")}</label>
                            <FieldError
                              error={errors.name}
                              touched={touched.name}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="group">
                            <input
                              type="text"
                              required
                              name={"email"}
                              onChange={handleChange}
                              onBlur={() => setFieldTouched("email")}
                              value={values.email}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label>{lang.get("email", "Email")}</label>
                            <FieldError
                              error={errors.email}
                              touched={touched.email}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="group">
                            <input
                              type="text"
                              required
                              name={"phone"}
                              onChange={handleChange}
                              onBlur={() => setFieldTouched("phone")}
                              value={values.phone}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label>
                              {lang.get("phoneNumber", "Phone Number")}
                            </label>
                            <FieldError
                              error={errors.phone}
                              touched={touched.phone}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="group">
                            <input
                              type="text"
                              required
                              name={"title"}
                              onChange={handleChange}
                              onBlur={() => setFieldTouched("title")}
                              value={values.title}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label>
                              {lang.get("purpose", "Purpose of Contact")}
                            </label>
                            <FieldError
                              error={errors.title}
                              touched={touched.title}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <textarea
                          placeholder={lang.get(
                            "writeMessage",
                            "Write your message here."
                          )}
                          name={"message"}
                          onChange={handleChange}
                          onBlur={() => setFieldTouched("message")}
                          value={values.message}
                        />
                        <div style={{ padding: "0px 20px" }}>
                          <FieldError
                            error={errors.message}
                            touched={touched.message}
                          />
                        </div>
                      </Row>
                      {!sending ? (
                        <Button
                          color=" yt-contact-send-btn"
                          onClick={handleSubmit}
                        >
                          {lang.get("send", "Send")}
                        </Button>
                      ) : (
                        <div
                          className="yt-contact-send-btn"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <CgSpinner
                            style={{ color: "black", fontSize: 32 }}
                            className="w3-spin"
                          />
                        </div>
                      )}
                    </div>
                  </Form>
                </Fragment>
              );
            }}
          </Formik>
        </div>
      </Container>
    </section>
  ) : (
    <SuccessBlock
      title={lang.get("titleSuccessMessage", "Message Sent Successfully")}
      message={lang.get(
        "successMessage",
        "We will connect with you soon regarding your query."
      )}
    />
  );
}

export default ContactForm;
