// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export default {
//   apiKey: 'AIzaSyBumz-agVen5XKNFEENkbPFttlTqxdfQho',
//   authDomain: 'manandmachine-ecom.firebaseapp.com',
//   projectId: 'manandmachine-ecom',
//   storageBucket: 'manandmachine-ecom.appspot.com',
//   messagingSenderId: '702959632239',
//   appId: '1:702959632239:web:fdf5b9e17c8694adb4f29a',
// };

export default {
  apiKey: "AIzaSyDzRYDhtVZU7gZMnAupntpgvZnP0Ke2BE8",
    authDomain: "bigbargains-f48c3.firebaseapp.com",
    projectId: "bigbargains-f48c3",
    storageBucket: "bigbargains-f48c3.appspot.com",
    messagingSenderId: "937027681600",
    appId: "1:937027681600:web:faa519d8883ada688d0ea5",
    measurementId: "G-R5YM6K6NSE"
}
