export default {
  CLEAR: 0,
  SAVE: 1,
  CHANGE: 2,
  ADDFAVOURITE: "USER_ADD_PRODUCT_INTO_FAVOURITE",
  REMOVEFAVOURITE: "USER_REMOVE_PRODUCT_INTO_FAVOURITE",
  DEFAULT: "DEFAULT",
  SET_ADDRESS : "SET_ADDRESS",
  SET_NOTIFICATIONS : 'SET_NOTIFICATIONS'
};
