import React from 'react';
import './css/index.scoped.css';
// import { Formik, Form, Field } from "formik";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function SizeChartModal(props) {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="cm-small-modal-6"
      >
        <ModalHeader toggle={props.toggle} className="size-chart-title-bar p-4">
          <span>Size Chart</span>
        </ModalHeader>
        <ModalBody className="py-4 px-5 yt-add-modal-body">
          <div className="body-container">
            {props.images?.map((item) => (
              <div className="ad-addr-body-wrap text-center mb-3">
                <img src={item.image || '#null'} className="img-fluid w-100" />
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default SizeChartModal;
{
  /* <FormGroup className="yt-number-filed">
<div className="profile-form-field fields-active error">
  <span className="profile-form-tag">Pin Code</span>
  <Input
    className="py-2 "
    type="number"
    name="phoneno"
    id="phoneno"
  />
</div>
</FormGroup> */
}
