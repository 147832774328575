import React, { useRef, useState, useEffect, Fragment } from "react";
import { Container, Button } from "reactstrap";
import Carousel from "react-elastic-carousel";
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
  IoIosStar,
} from "react-icons/io";
import { FavouriteProductSet } from "../../components";
import { useHistory } from "react-router";
import commands from "../../commands";
import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import langg from "../../language";
import getConfig from "../../config";
import "./css/index.scoped.css";
import PageLoadingBlock from "../page-loading-block";
import { numberWithCommas } from "../../utils";
import { LazyLoadImage } from "react-lazy-load-image-component";

function TitleBar(props) {
  const lang = new langg("common");
  if (props.name != undefined) {
    return (
      <div className="yt-produstslider-info d-flex justify-content-between align-items-center ml-4">
        <h4 className="yt-comonent-top-title my-0">{props.name}</h4>
        {props.onViewMore && (
          <div className="yt-comonent-link">
            <Button color=" yt-component-more px-0" onClick={props.onViewMore}>
              {lang.get("viewAll", "View All")}
            </Button>
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
}

function ProductCard(props) {
  const history = useHistory();
  const state = AuthState.get();
  const [products, setProducts] = useState();
  const lang = new langg("common");
  const [newProduct, setNewProduct] = useState([]);
  const [similar, setSimilar] = useState(null);
  const config = getConfig();
  const [check, setCheck] = useState(false);

  let carousel = useRef();
  let p = 0;
  let Breakpoints = [
    { width: 320, itemsToShow: 2, itemsToScroll: 2 },
    { width: 730, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1300, itemsToShow: 5 },
  ];

  useEffect(() => {
    // if(props.name=="Today’s Deals"){
    if (props.products?.length > 0) {
      setProducts(props.products);
    }
    // }
  }, [props]);

  useEffect(() => {
    if (props.products?.length === 0) {
      setSimilar(true);
    }
  }, [props.products?.length]);

  const productDetails = (a) => {
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  function addToWishlist(product) {
    checkStatus(product.id, true);
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: "Product added to wishlist successfully",
              type: "success",
            },
          ]);
          commands.cache.getHomeProducts();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  function removeFromWishlist(product) {
    checkStatus(product.id, false);
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: "Product removed from wishlist successfully",
              type: "danger",
            },
          ]);
          commands.cache.getHomeProducts();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  const checkStatus = (id, status) => {
    var newProduct = products
      .filter((y) => y.id === id)
      .map((x) => {
        x.is_wishlisted = status;
        return x;
      });
    setCheck(true);
    setNewProduct({
      ...newProduct,
      newProduct,
    });
  };

  function getList() {
    let list = [];
    let lolist = [];

    products.forEach((product, index) => {
      let percentageValue = parseInt(
        ((product.actual_price - product.sale_price) / product.actual_price) *
          100
      );
      if (
        !(
          Array.isArray(product.product_variants) &&
          product.product_variants.length > 0
        )
      ) {
        list.push(
          <div className="slider-container">
            <div className="yt-item-slider" popkey={index}>
              <div className="product yt-product-slider-cpnt text-center">
                {/* <img
                  src={
                    product.images.length > 0
                      ? product.images[0].image
                      : "/images/thumb/missing.png"
                  }
                  className="yt-product-bg-image w3-ripple"
                  alt="bigbargains"
                  onClick={() => productDetails(product)}
                /> */}

                <LazyLoadImage
                  placeholderSrc={process.env.PUBLIC_URL + "/favicon.png"}
                 className="yt-product-bg-image w3-ripple"
                  alt="bigbargains"
                  effect="blur"
                  width="100%"
                  src={
                    product.images.length > 0
                      ? product.images[0].original_image
                      : "/images/thumb/missing.png"
                  }
                  onClick={() => productDetails(product)}
                />
                <div className="d-flex justify-content-between align-items-center  yt-top-offer-whl">
                  <div
                    className="text-left px-4 mt-4"
                    style={{ opacity: product.total_ratings > 0 ? 1 : 0 }}
                  >
                    <span>
                      {product.average_rating}{" "}
                      <IoIosStar className="rating-star-icon" />{" "}
                      <span className="yt-product-rating ">
                        | {product.total_review}
                      </span>
                    </span>
                  </div>
                  <div className="text-right mr-3">
                    {/* {console.log('narendra', checkStatus(product))} */}
                    <FavouriteProductSet
                      onDataId={index}
                      onPageType="shop"
                      isFav={product.is_wishlisted}
                      onClick={() =>
                        product.is_wishlisted
                          ? removeFromWishlist(product)
                          : addToWishlist(product)
                      }
                    />
                  </div>
                </div>

                <div
                  className="mt-2 yt-product-title ellises text-left px-4 text-capitalize"
                  title={product.name}
                >
                  {product.name}
                </div>
                {/* <div className="yt-product-code text-left px-4 pt-2">
                  Product Code: {product.system_sku}
                </div> */}
                <div className="price yt-price-wrap text-left pt-2 pb-3 px-4">
                  {product.on_sale && (
                    <>
                      <span className="price1 yt-product-sale-price">
                        {config.currency} {numberWithCommas(product.sale_price)}
                      </span>
                      <span className="price2 yt-product-reg-price2">
                        {config.currency}{" "}
                        {numberWithCommas(product.actual_price)}
                      </span>
                      {product.on_sale && (
                        <span className="yt-product-off text-center p-1">
                          {percentageValue < 1
                            ? "sale"
                            : " " +
                              parseInt(
                                ((product.actual_price - product.sale_price) /
                                  product.actual_price) *
                                  100
                              ) +
                              "% Off"}
                        </span>
                      )}
                    </>
                  )}
                  {!product.on_sale && (
                    <span className="price1 yt-product-sale-price">
                      {config.currency} {numberWithCommas(product.actual_price)}
                    </span>
                  )}
                </div>
                {/* {product.stock_qty >= 1 &&
                  product.current_availability === 'in_stock' ? (
                  <Fragment>
                    {product.is_in_cart && (
                      <Button
                        color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                        onClick={() => history.push('/cart')}
                      >
                        {lang.get('goToCart', 'Go to Cart')}
                      </Button>
                    )}
                    {!product.is_in_cart && (
                      <Button
                        color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                        onClick={() =>
                          commands.cart.addItem({
                            productId: product.id,
                            quantity: 1,
                            onSuccess: () => commands.cache.getHomeProducts(),
                          })
                        }
                      >
                        {lang.get('placeYourOrder', 'Place Your Order')}
                      </Button>
                    )}
                  </Fragment>
                ) : (
                  <Button
                    disabled
                    color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                    onClick={() =>
                      commands.cart.addItem({
                        productId: product.id,
                        quantity: 1,
                        onSuccess: () => commands.cache.getHomeProducts(),
                      })
                    }
                  >
                    {lang.get('outOfStock', 'No Stock')}
                  </Button>
                )} */}
              </div>
            </div>
          </div>
        );
      }

      product?.product_variants?.forEach(
        (x, i) =>
          x.is_master &&
          list.push(
            <div className="slider-container">
              <div className="yt-item-slider" popkey={index + "-" + i}>
                <div className="product yt-product-slider-cpnt text-center">
                  {/* <img
                    src={
                      x.images.length > 0
                        ? x.images[0].image
                        : "/images/thumb/missing.png"
                    }
                    className="yt-product-bg-image"
                    alt="bigbargains"
                    onClick={() => productDetails(product)}
                  /> */}
                  <LazyLoadImage
                  placeholderSrc={process.env.PUBLIC_URL + "/favicon.png"}
                 className="yt-product-bg-image"
                  alt="bigbargains"
                  effect="blur"
                  width="100%"
                  src={
                    x.images.length > 0
                      ? x.images[0].original_image
                      : "/images/thumb/missing.png"
                  }
                  onClick={() => productDetails(product)}
                />
                  <div className="d-flex justify-content-between align-items-center  yt-top-offer-whl">
                    <div
                      className="text-left px-4 mt-4"
                      style={{ opacity: product.total_ratings > 0 ? 1 : 0 }}
                    >
                      <span>
                        {product.average_rating}{" "}
                        <IoIosStar className="rating-star-icon" />{" "}
                        <span className="yt-product-rating ">
                          | {product.total_review}
                        </span>
                      </span>
                    </div>

                    <div className="text-right mr-3">
                      {/* {console.log('narendra', checkStatus(product))} */}
                      <FavouriteProductSet
                        onDataId={index}
                        onPageType="shop"
                        isFav={product.is_wishlisted}
                        onClick={() =>
                          product.is_wishlisted
                            ? removeFromWishlist(product)
                            : addToWishlist(product)
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="mt-2 yt-product-title ellises text-left px-4 text-capitalize"
                    title={product.name} 
                  >
                    {product.name}
                  </div>
                  {/* <div className="yt-product-code text-left px-4 pt-2">
                    Product Code: {product.system_sku}
                  </div> */}
                  <div className="price yt-price-wrap text-left pt-2 pb-3 px-4">
                    {x.on_sale && (
                      <>
                        <span className="price1 yt-product-sale-price">
                          {config.currency} {numberWithCommas(x.sale_price)}
                        </span>
                        <span className="price2 yt-product-reg-price2">
                          <del>
                            {config.currency} {numberWithCommas(x.variant_actual_price)}
                          </del>
                        </span>
                        {x.on_sale && (
                          <span className="yt-product-off text-center p-1 ml-1">
                            {parseInt(
                              ((x.variant_actual_price - x.sale_price) /
                                x.variant_actual_price) *
                                100
                            )}
                            % Off
                          </span>
                        )}
                      </>
                    )}
                    {!x.on_sale && (
                      <span className="price1 yt-product-sale-price">
                        {config.currency} {numberWithCommas(x.variant_actual_price)}
                      </span>
                    )}
                  </div>
                  {/* {x.stock_qty >= 1 && x.current_availability === 'in_stock' ? (
                    <Fragment>
                      {x.is_in_cart && (
                        <Button
                          color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                          onClick={() => history.push('/cart')}
                        >
                          {lang.get('goToCart', 'Go to Cart')}
                        </Button>
                      )}
                      {!x.is_in_cart && (
                        <Button
                          color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                          onClick={() =>
                            commands.cart.addItem({
                              productId: product.id,
                              variantId: x.id,
                              quantity: 1,
                              onSuccess: () => commands.cache.getHomeProducts(),
                            })
                          }
                        >
                          {lang.get('placeYourOrder', 'Place Your Order')}
                        </Button>
                      )}
                    </Fragment>
                  ) : (
                    <Button
                      disabled
                      color="secondary yt-product-add-btn buttoncart w3-ripple py-3"
                      onClick={() =>
                        commands.cart.addItem({
                          productId: product.id,
                          variantId: x.id,
                          quantity: 1,
                          onSuccess: () => commands.cache.getHomeProducts(),
                        })
                      }
                    >
                      {lang.get('outOfStock', 'No Stock')}
                    </Button>
                  )} */}
                </div>
              </div>
            </div>
          )
      );
      list = [...list, ...lolist];
    });

    return list;
  }

  let list = [];
  if (products && Array.isArray(products) && products.length > 0) {
    list = getList();
  }
  const showCard = list.length > 0;
  return products &&
    Array.isArray(products) &&
    products.length > 0 &&
    showCard ? (
    <section className="hp-product-slider">
      <Container>
        <TitleBar name={props.name} onViewMore={props.onViewMore} />
        <div className="yt-component-wrapper yt-slider-component py-4 mb-3">
          <IoIosArrowDropleft
            className="yt-slider-left img-fluid"
            onClick={() => carousel.slidePrev()}
          />
          <IoIosArrowDropright
            className="yt-slider-right img-fluid"
            width="20"
            height="20"
            onClick={() => carousel.slideNext()}
          />
          <Carousel
            itemsToShow={15}
            itemsToScroll={1}
            pagination={false}
            showArrows={false}
            ref={(ref) => (carousel = ref)}
            breakPoints={Breakpoints}
          >
            {list}
          </Carousel>
        </div>
      </Container>
    </section>
  ) : (
    <section className="hp-product-slider">
      <Container>
        <TitleBar name={props.name} />
        {props.productType !== "similarProduct" && (
          <PageLoadingBlock
            title={"Please wait..."}
            message={"Fetching Products"}
          />
        )}
        {props.productType === "similarProduct" &&
          (!similar ? (
            <PageLoadingBlock
              title={"Please wait..."}
              message={"Fetching Products"}
            />
          ) : (
            <div className="mt-2">No Products Available</div>
          ))}
      </Container>
    </section>
  );
}

export default ProductCard;
