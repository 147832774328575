import React, { useState, useEffect, Fragment } from 'react';

import service, { serviceTypes } from '../../services';
import AuthState from '../../redux/states/auth';
import {
  Footer,
  Header,
  ProfileComponent,
  ErrorOccuredBlock,
  PageLoadingBlock,
  FeatureBar,
  AppStoreBanner,
} from '../../components';

import './css/index.scoped.css';


function ProfilePage(props) {
  const authState = AuthState.get();
  const [wishlist, setWishlist] = useState();
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState( );
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    console.log("11")
    if (authState.user.is_guest) {
      props.history.push('/login');
    }
    getWishlistProducts();
    getMyOrders();
    getNotifications();
  }, []);

  function getWishlistProducts() {
    const serviceType = serviceTypes.getWishlist(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: 'Oh Noes!, 404 Not Found',
              message: 'The product you are looking for is not available!',
              buttonText: 'Go to Home',
              onButtonPress: () => props.history.push('/'),
            });
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: error.message,
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setWishlist(undefined);
                getWishlistProducts();
              },
            });
          }
        } else if (response) {
          if (typeof response.data === 'object') {
            setWishlist(response.data.data);
            setErrorData(null);
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occurred',
              message: 'Unknown response received from server.',
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setWishlist(undefined);
                getWishlistProducts();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getMyOrders() {
    setLoading(true); 
    const serviceType = serviceTypes.getMyOrdersList(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: 'Oh Noes!, 404 Not Found',
              message: 'The product you are looking for is not available!',
              buttonText: 'Go to Home',
              onButtonPress: () => props.history.push('/'),
            });
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occurred',
              message: error.message,
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setOrders(undefined);
                getMyOrders();
              },
            });
          }
        } else if (response) {
          if (typeof response.data.data === 'object') {
            setOrders(response?.data?.data?.order);
            setErrorData(null);
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occurred',
              message: 'Unknown response received from server.',
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setOrders(undefined);
                getMyOrders();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getNotifications() {

    const serviceType = serviceTypes.getNotifications(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: 'Oh Noes!, 404 Not Found',
              message: 'The product you are looking for is not available!',
              buttonText: 'Go to Home',
              onButtonPress: () => props.history.push('/'),
            });
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: error.message,
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setNotifications(undefined);
                getNotifications();
              },
            });
          }
        } else if (response) {
          if (typeof response.data.data === 'object') {
            setNotifications(response?.data?.data);
            setErrorData(null);
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: 'Unknown response received from server.',
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setNotifications(undefined);
                getNotifications();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  return (
    <div>
      <Header onProps={props} />
      {/*<HeroBanner />*/}

      {wishlist && orders? (
        <ProfileComponent
          onProps={props}
          wishlist={wishlist}
          order={orders}
          notifications={notifications}
          getWishlist={getWishlistProducts}
          getOrders={getMyOrders}
          getNotifications={getNotifications}
        />
      ) : (
        <Fragment>
          {!loading && (
            <ErrorOccuredBlock
              title={errorData?.title || 'Oh Noes!, Error Occured'}
              message={errorData?.message || 'Unknown Error Occured.'}
              buttonText={errorData?.buttonText || 'Go to Home'}
              onButtonPress={
                errorData?.onButtonPress || (() => props.history.push('/'))
              }
            />
          )}
          {loading && (
            <PageLoadingBlock
              title={'Please wait...'}
              message={'Your Profile is loading!'}
              buttonText={'Go to Home'}
              onButtonPress={() => props.history.push('/')}
            />
          )}
        </Fragment>
      )}

      <FeatureBar />
      <AppStoreBanner />
      <Footer />
    </div>
  );
}
export default ProfilePage;
