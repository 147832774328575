import React from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import langg from "../../language";
import "./css/index.scoped.css";

function Footer() {
  const history = useHistory();
  const lang = new langg("footer");
  const routeToAll = (value) => {
    if (value !== undefined) {
      let path = "/" + value;
      window.scrollTo(0, 0);
      history.push(path);
    } else {
      let path = "/";
      window.scrollTo(0, 0);
      history.push(path);
    }
  };
  const routeHelpCenter = (value) => {
    if (value !== undefined) {
      let path = "/help-center/" + value;
      window.scrollTo(0, 0);
      history.push(path);
    } else {
      let path = "/help-center";
      window.scrollTo(0, 0);
      history.push(path);
    }
  };
  return (
    <footer>
      <div className="footercontainer yt-main-footer">
        <Container>
          <Row className="justify-content-between yt-main-footer-row">
            <Col
              xs={12}
              sm={7}
              lg={5}
              className="yt-col yt-first-col first-side"
            >
              <div className="d-flex">
                <div
                  className="logobox w3-ripple"
                  onClick={() => history.push("/")}
                >
                  <img
                    src={require("./images/Logo@3x.png")}
                    className="logoimage"
                    alt="BigBargains"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className=" yt-text-about" style={{display:'none'}}>
                  SHOP WITH ZERO BARGAINS @ BIGBARGAINS.CO.IN
                </div>
              </div>
              <div className="d-flex">
                <div className="social_all">
                  <a
                    href="https://www.facebook.com/BigBargainscoin-104730628541109"
                    target="_blank"
                  >
                    <img
                      src={require("./images/facebook.png")}
                      className="social_logos w3-ripple"
                      alt="social"
                    />
                  </a>{" "}
                  |
                  <a
                    href="https://www.instagram.com/bigbargains.co.in/"
                    target="_blank"
                  >
                    <img
                      src={require("./images/instagram.png")}
                      className="social_logos w3-ripple"
                      alt="social"
                    />
                  </a>{" "}
                  |
                  <a
                    href="https://youtube.com/channel/UC906Uer5zECVDBc_BEHOnxA"
                    target="_blank"
                  >
                    <img
                      src={require("./images/Youtube.png")}
                      className="social_logos w3-ripple"
                      alt="social"
                    />
                  </a>{" "}
                  |
                  <a href="https://twitter.com/BigBargains3 " target="_blank">
                    <img
                      src={require("./images/twitter.png")}
                      className="social_logos w3-ripple"
                      alt="social"
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={5} lg={2} className="yt-foote-link-col yt-col">
              <div className="yt-footer-widget-title text-nowrap">
                {lang.get("useful_links", "Useful Links")}
              </div>
              <div className="yt-footler-menu-links">
                <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeToAll("aboutus");
                  }}
                >
                  {lang.get("about_us", "About Us")}
                </div>
                <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeHelpCenter("Delivery, Cancellation & Returns");
                  }}
                >
                  {lang.get("delivery_and_returns", "Delivery & Returns")}
                </div>

                {/* <div className="yt-ftr-link w3-ripple text-nowrap"
                  onClick={() => {
                    routeHelpCenter("Site Map");
                  }}
                >{lang.get("site_map", "Site Map")}</div> */}

                <div
                  className="yt-ftr-link avitem w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeToAll("contactus");
                  }}
                >
                  {lang.get("contact_us", "Contact Us")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={5} lg={2} className="yt-foote-link-col yt-col">
              <div className="yt-footer-widget-title">
                {lang.get("quick_links", "Quick Links")}
              </div>
              <div className="yt-footler-menu-links">
                {/* <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeHelpCenter();
                  }}
                >
                  {lang.get('help_center', 'Help Center')}
                </div> */}
                
                <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeHelpCenter("Frequently Asked Questions");
                  }}
                >
                  FAQ's
                </div>
                <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeHelpCenter("Terms of  service");
                  }}
                >
                  Terms of service
                </div>
                <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity"
                  onClick={() => {
                    routeHelpCenter("Privacy Policy");
                  }}
                >
                  {lang.get("privacy_policy", "Privacy Policy")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={5} lg={3} className="yt-col yt-last-col">
              {/* <img
                src={require("./images/promotions.png")}
                className="logo-image w3-hover-shadow"
                alt="bigbargains"
                onClick={() => history.push("/shop?page=1&per_page=12")}
              /> */}
              {/* <div classNam="yt-footer-widget-title"> BIGBARGAINS E COMMERCE SOLUTIONS PRIVATE LIMITED</div> 
              <div
                  className="yt-ftr-link w3-ripple text-nowrap w3-hover-opacity">
              Address : # No.
                
              30, “PRAGATHI MANSION” 2nd Floor Dr. DVG Road, Basavanagudi,
                Bangalore -560 004. Office Phone NO: 080-42190504
              </div> */}
              <div className="yt-footer-widget-title" style={{lineHeight: '26px'}}>
              BIGBARGAINS E COMMERCE SOLUTIONS PRIVATE LIMITED
              </div>
                <div
                  className="yt-ftr-link w3-ripple" style={{lineHeight: '20px'}}>
                 Address : # No.
                
                30, “PRAGATHI MANSION” 2nd Floor Dr. DVG Road, Basavanagudi,
                  Bangalore -560 004. Office Phone NO: 080-42190504
                  </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottombar">
        <Container>
          <p className="m-0 yt-copyright-text">
            {("copyrights", `Copyright © ${new Date().getFullYear()}`)}
            <span
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
              style={{ cursor: "pointer" }}
            >
              {" "}
              Bigbargains.co.in
            </span>
            , India. All rights reserved.
          </p>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
