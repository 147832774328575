import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Footer,
  Header,
  ErrorOccuredBlock,
  PageLoadingBlock,
} from '../../components';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './css/index.scoped.css';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import service, { serviceTypes } from '../../services';
import AuthState from '../../redux/states/auth';

function HelpCenter(props) {
  const tabName = props.match.params.slug;
  const [activeTab, setActiveTab] = useState('1');
  const [activeMobileTab, setactiveMobileTab] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [helpCenterData, setHelpCenterData] = useState();
  const [faqData, setFaqData] = useState();
  const authState = AuthState.get();
  const isLoggedIn =
    authState.guest_user === false &&
    typeof authState?.loginData?.user === 'object';

  useEffect(() => {
    getHelpceterData();
    getFAQData();
  }, []);

  function getHelpceterData() {
    const serviceType = serviceTypes.getHelpCenterData();
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: 'Oh Noes!, 404 Not Found',
              message: 'The product you are looking for is not available!',
              buttonText: 'Go to Home',
              onButtonPress: () => props.history.push('/'),
            });
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: error.message,
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setHelpCenterData(undefined);
                getHelpceterData();
              },
            });
          }
        } else if (response) {
          if (typeof response.data.data.help_centers === 'object') {
            setHelpCenterData(response?.data?.data?.help_centers);
            setErrorData(null);
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: 'Unknown response received from server.',
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setHelpCenterData(undefined);
                getHelpceterData();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getFAQData() {
    const serviceType = serviceTypes.getFAQs();
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response) {
          setFaqData(response?.data?.data.faqs)
          setErrorData(null);
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const history = useHistory();
  const routeToProfile = () => {
    history.push(isLoggedIn ? '/profile' : '/');
  };

  const routeHelpCenter = (value) => {
    if (value !== undefined) {
      let path = '/help-center/' + value;
      history.push(path);
    } else {
      let path = '/help-center';
      history.push(path);
    }
  };
  const routeHelpCenterMb = (value) => {
    if (value !== undefined) {
      let path = '/help-center/' + value;
      history.push(path);
      setactiveMobileTab(!activeMobileTab);
    } else {
      let path = '/help-center';
      history.push(path);
      setactiveMobileTab(!activeMobileTab);
    }
  };
  if (tabName !== undefined && helpCenterData !== undefined) {
    let matchTabName = '';
    helpCenterData.map((data, index) => {
      if (tabName === data.title) {
        matchTabName = index + 1;
      } else if (tabName === "Frequently Asked Questions") {
        matchTabName = 7
      }
    });
    toggle(matchTabName);
  }
  if (tabName === undefined && helpCenterData !== undefined) {
    toggle(1);
  }

  return (
    <div>
      <Header onProps={props} />
      {/*<HeroBanner />*/}
      <Container
        className={activeMobileTab ? 'yt-mobile-datapg' : 'yt-desktop-datapg'}
      >
        <Row>
          <Col md={12}>
            <div className="pageroute hc-breadcrumbs my-3 mb-5">
              <Link to="/">
                <span
                  className="hc-home w3-hover-opacity w3-ripple"
                  style={{ cursor: 'default' }}
                >
                  Home
                </span>
              </Link>
              {' > '}
              {isLoggedIn && (
                <>
                <Link to="/profile">
                  <span
                    className="hc-home w3-hover-opacity w3-ripple"
                    style={{ cursor: 'default' }}
                  >
                    Profile
                  </span>
                </Link>
                {' > '}
                </>
              )}
              <span className="currpage hc-current">Help Center</span>
            </div>
          </Col>
        </Row>
        <section className="mb-4 d-block hc-mb-30">
          <div
            className="hc-beckfrom-ct-page d-flexx align-items-center hc-mb-30 w3-ripple my-0 mb-4"
            onClick={() => {
              routeToProfile();
            }}
            style={{ cursor: 'pointer' }}
          >
            {isLoggedIn && <FaLongArrowAltLeft className="hcp-back-arrow" />}{' '}
            <span className="pl-2 hc-back-tag" style={{ opacity: 1 }}>
              Help Center
            </span>
          </div>
          <Row className="yt-cm-row">
            <Col xs={12} sm={12} md={5} lg={4} className="yt-cm-lt-col col">
              {helpCenterData && (
                <div className="hc-inner-wrap bg-white radius-10 hc-mb-30">
                  <div className="hc-inner-contnet yt-desk-hc">
                    <ul className="p-0 m-0 list-style-none hc-tabs-name">
                      {helpCenterData.map((data, index) => (
                        <li
                          className={classnames({
                            active: activeTab === index + 1,
                          })}
                          onClick={() => {
                            routeHelpCenter(data.title);
                          }}
                        >
                          {data.title}
                        </li>
                      ))}
                      <li
                        className={classnames({
                          active: activeTab === 7
                        })}
                        onClick={() => {
                          routeHelpCenter("Frequently Asked Questions")
                        }}
                      >
                        Frequently Asked Questions
                        </li>
                    </ul>
                  </div>
                  <div className="hc-inner-contnet yt-tab-movile-hc">
                    <ul className="p-0 m-0 list-style-none hc-tabs-name">
                      {helpCenterData.map((data, index) => (
                        <li
                          className={classnames({
                            active: activeTab === index + 1,
                          })}
                          onClick={() => {
                            routeHelpCenterMb(data.title);
                          }}
                        >
                          {data.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </Col>
            <Col xs={12} sm={12} md={7} lg={8} className="yt-cm-rt-col col">
              {!helpCenterData && (
                <Fragment>
                  {!loading && (
                    <ErrorOccuredBlock
                      title={errorData?.title || 'Oh Noes!, Error Occured'}
                      message={errorData?.message || 'Unknown Error Occured.'}
                      buttonText={errorData?.buttonText || 'Go to Home'}
                      onButtonPress={
                        errorData?.onButtonPress ||
                        (() => props.history.push('/'))
                      }
                    />
                  )}
                  {loading && (
                    <PageLoadingBlock
                      title={'Please wait...'}
                      message={'Fetching helpcenter'}
                      buttonText={'Go to Home'}
                      onButtonPress={() => props.history.push('/')}
                    />
                  )}
                </Fragment>
              )}
              {helpCenterData && (
                <div className="hc-inner-wrap hc-tab-content bg-white radius-10 hc-mb-30 mt-0">
                  <div className="hc-inner-content">
                    <>
                      {helpCenterData.map((data, index) => (
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={index + 1}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.content.replace(/\n/g, '<br />'),
                              }}
                            />
                          </TabPane>
                        </TabContent>
                      ))}
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={7}>
                          {faqData?.map((faq, idx) => (
                            <div className="hc-tab-inner-data hc-mb-30">
                              <h3 className="hc-tab-sub-title mt-0">{faq.id} {faq.title}</h3>
                              <p className="m-0 ht-tab-description">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: faq.content.replace(/\n/g, '<br />'),
                                  }}
                                />
                              </p>
                            </div>
                          ))}
                        </TabPane>
                      </TabContent>
                    </>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </section>
      </Container>

      <Footer />
    </div>
  );
}



export default HelpCenter;
