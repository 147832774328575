/* global FB */
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './css/index.scoped.css';
import service, { serviceTypes } from '../../../services';
import 'firebase/auth';
import copy from "copy-to-clipboard";
import { FaCopy } from 'react-icons/fa';
import getConfig from '../../../config'

function UserLogout(props) {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const config = getConfig();
  const [myCoupons, setMyCoupons] = useState(undefined)
  const [couponCopied, setCoupon] = useState(false);

  useEffect(() => {
    if(myCoupons === undefined){
      getCoupons()
    }
  }, [props.toggle]);

  function getCoupons(){
    const serviceType =  serviceTypes.getCoupons();
    service(serviceType)
    .then(({response,error})=>{
      if(error){
        window.notify([{ message: "cannot load coupons" }]);
      }else if(response?.data?.success){
        setMyCoupons(response?.data?.data)
      }
    })
  }
  function copyLink(coupon,index){
      copy(coupon?.code)
      setCoupon(index);
  };
  return (
    <div className="cm-main-modal-wrapper">
      <Modal
        isOpen={props.isOpen}
        className=""
        centered={true}
        modalClassName="popopop"
      >
        <ModalHeader
          toggle={props.toggle}
          className="coupon-title-bar  border-0"
        >
          <span>View Available Coupons</span>
        </ModalHeader>
        <ModalBody className="yt-coupon-wrap">
          {myCoupons?.map((coupon,index)=>(
             <div className="coupon-body-text" onClick={()=>copyLink(coupon,index)}>
             <p className={couponCopied === index ? "active" : null}>{coupon.code} {couponCopied === index ? "Code Copied!" : null}<FaCopy className={couponCopied === index ? "active" : null} /></p>
             <span>Get {coupon.discount} {coupon.discount_type == "flat" ? config.currency : '%'} Off on order of minimum {config.currency}{coupon.min_cart_value} to {config.currency}{coupon.max_cart_value}.</span>
           </div>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
}
export default UserLogout;
