import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import langg from '../../language';
import './css/index.scoped.css';

function FeatureBar() {
  const lang = new langg('homeScreen');

  return (
    <section className="yt-featured-bar-main">
      <Container>
        <div className="customcontainer hp-featured-wrapper">
          <Row className="align-items-center yt-cm-fb-row">
            <Col xs={12} sm={4} lg={4} className="yt-cm-fb-col">
              <Row className="align-items-center yt-fb-inner-wrap">
                <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
                  <div className="yt-ft-img d-flex align-items-center justify-content-center">
                    <img
                      src={require("./images/truck.png")}
                      className="logoimage"
                      alt="bigBargains"
                    />
                    <img
                      src={require("./images/truck-white.png")}
                      className="logoimage d-none logo-white"
                      alt="bigBargains"
                    />
                  </div>
                </Col>
                <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
                  <div className="hp-fb-inner-wrap py-3">
                    <div className="feature_head first">{lang.get("fastDelivery", "Fast Delivery")}</div>
                    <div className="feature_text first">
                      {lang.get("freedeliverytext", "Free Shipping on all order")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} lg={4} className="yt-cm-fb-col">
              <Row className="align-items-center yt-fb-inner-wrap">
                <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
                  <div className="yt-ft-img d-flex align-items-center justify-content-center">
                    <img
                      src={require("./images/pay.png")}
                      className="logoimage"
                      alt="bigBargains"
                    />
                    <img
                      src={require("./images/pay-white.png")}
                      className="logoimage d-none logo-white"
                      alt="bigBargains"
                    />
                  </div>
                </Col>
                <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
                  <div className="hp-fb-inner-wrap py-3">
                    <div className="feature_head">{lang.get("securePaymentTitle", "Secure Payment")}</div>
                    <div className="feature_text">
                    On every order over ₹ 100.00
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} lg={4} className="yt-cm-fb-col">
              <Row className="align-items-center yt-fb-inner-wrap">
                <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
                  <div className="yt-ft-img d-flex align-items-center justify-content-center">
                    <img
                      src={require("./images/help.png")}
                      className="logoimage "
                      alt="bigBargains"
                    />
                    <img
                      src={require("./images/help-white.png")}
                      className="logoimage d-none logo-white"
                      alt="bigBargains"
                    />
                  </div>
                </Col>
                <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
                  <div className="hp-fb-inner-wrap border-md-0 py-3">
                    <div className="feature_head">Support 8/6</div>
                    <div className="feature_text">
                    Monday to Saturday / 10.00 AM to 6.00 PM
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>
      </Container>
    </section>
  );
}

export default FeatureBar;
