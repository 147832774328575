import React, { useEffect, useState, Fragment } from 'react';
import {Link} from 'react-router-dom'
import './css/index.scoped.css';
import { Header, Footer, AboutUsReviews, PageLoadingBlock, ErrorOccuredBlock, AppStoreBanner, FeatureBar } from '../../components';
import { Container } from 'reactstrap';
import commands from '../../commands';
import parse from 'html-react-parser';

function AboutUs(props) {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true)
  const [errorData, setErrorData] = useState(null)

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoading(true)
    if (content === undefined) {
      commands.pages
        .getAboutUs()
        .then((res) => {
          setContent(parse(res));
          setLoading(false)
        })
        .catch((error) => {
          if (error) {
            window.notify([{ message: error.message }]);
            if (error.status === 404) {
              setErrorData({
                title: 'Oh Noes!, 404 Not Found',
                message: 'The product you are looking for is not available!',
                buttonText: 'Go to Home',
                onButtonPress: () => props.history.push('/'),
              });
            } else {
              setErrorData({
                title: 'Oh Noes!, Error Occured',
                message: 'No Content Available',
                buttonText: 'Retry',
                onButtonPress: () => {
                  setLoading(true);
                  setContent();
                  setLoader(true);
                  getData()
                },
              });
            }
          }
          else {
            setContent(<div>No Content Available</div>);
            setLoading(false)
          }

        });
    }
    setLoading(false)
  }
  return (
    <div>
      <Header onProps={props} />
      {/*<HeroBanner />*/}
      <section className="yt-about-us-pg">
        {!content && (
          <Fragment>
            {errorData && (
              <ErrorOccuredBlock />
            )}
            {!loading && (
              <PageLoadingBlock
                title={'Please wait...'}
                message={'Fetching About Us'}
                buttonText={'Go to Home'}
                onButtonPress={() => props.history.push('/')}
              />
            )}
          </Fragment>
        )}
        {content && (
          <Container>
            <div className="yt-cm-mobile-bread">
              <div className="pageroute profile-pg-breadcrumbs">
                <Link to="/">
                <span className="profile-pg-home"  style={{ cursor: 'default' }}>Home {'>'}</span>{' '}
                </Link>
                <span className="" >About Us</span>
              </div>
              <h2 className="yt-profile-mb-ttl profile-pg-title yt-section-title">About Us</h2>
            </div>
            <div className="cardcontainer yt-main-wrapper border-radius-10 bg-white">
              <div className="customcard yt-inner-wrap">{content}</div>
            </div>
          </Container>
        )}
      </section>
      <AboutUsReviews />
      <FeatureBar />
      <AppStoreBanner />
      <Footer />
    </div>
  );
}
export default AboutUs;
