import React, { useState, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';

import { AllModal } from '../../../components';
import NoWishlist from './no-wishlist';
import commands from '../../../commands';
import { useHistory } from 'react-router-dom';
import service, { serviceTypes } from '../../../services';
import AuthState from '../../../redux/states/auth';
import { FavouriteProductSet } from '../../index';
import langg from '../../../language';
import getConfig from '../../../config';
import { IoIosStar } from 'react-icons/io';
import './css/index.scoped.css';
import { numberWithCommas } from "../../../utils";

function WhishListProductComponent({ products, ...props }) {
  const productList = products;
  const history = useHistory();
  const [ShowWLModal] = useState(false);
  const state = AuthState.get();
  const lang = new langg('common');
  const config = getConfig();

  function addToWishlist(product) {
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: 'Product added to wishlist successfully',
              type: 'success',
            },
          ]);
          props.getWishlist();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  function removeFromWishlist(product) {
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data?.success) {
          window.notify([
            {
              message: 'Product removed from wishlist successfully',
              type: 'danger',
            },
          ]);
          props.getWishlist();
        }
      })
      .finally(commands.user.refreshProfile);
  }

  return (
    <div className="profile-pg-whish-lt-inner-wrap profile-pg-inner-wrap radius-10 profile-pg-mb-30 profile-p-30">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-tab-content">
          <div className="profile-pg-wl-allproduct-main-wrap">
            {ShowWLModal ? <AllModal modalName="removewhishlist" /> : ''}
            <Row className="profile-pg-wl-cm-row-margin">
              {productList.map((product, index) => {
                let percentageValue = parseInt(
                  ((product.actual_price - product.sale_price) / product.actual_price) * 100
                );
                if (
                  !(
                    Array.isArray(product.product_variants) &&
                    product.product_variants.length > 0
                  )
                ) {
                  return (
                    <Col md={3} lg={3} className="px-2 col-xxl-3 yt-cm-wl-col">
                      <div className="product profile-pg-wl-sgl-product-cpnt text-center mb-4">
                        <div
                          className="w3-ripple"
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            src={product.images.length > 0 ? product.images[0].original_image : '/images/thumb/missing.png'}
                            className="yt-product-bg-image img-fluid"
                            alt="bigbargains"
                            onClick={() => history.push('/shop/' + product.id)}
                          />


                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <div
                              className="text-left px-4 mt-2"
                              style={{
                                opacity: product.total_ratings > 0 ? 1 : 0,
                              }}
                            >
                              <span>
                                {product.average_rating}{' '}
                                <IoIosStar className="rating-star-icon" />{' '}
                                <span className="yt-product-rating ">
                                  | {product.total_ratings}
                                </span>
                              </span>
                            </div>
                            <div className="text-right pr-2">
                              <FavouriteProductSet
                                onDataId={index}
                                onPageType="shop"
                                isFav={product.is_wishlisted}
                                onClick={() =>
                                  product.is_wishlisted
                                    ? removeFromWishlist(product)
                                    : addToWishlist(product)
                                }
                              />
                            </div>
                          </div>
                          <div onClick={() => history.push('/shop/' + product.id)}>
                          <div className="mt-2 profile-pg-wl-sgl-product-title text-left px-4 pb-0 mb-0">
                            {product.name}
                          </div>
                          <div className="price profile-pg-wl-sgl-price-wrap text-left px-4 mb-3">
                            {product.on_sale && (
                              <>
                                <span className="price1 profile-pg-wl-sgl-product-sale-price">
                                  {config.currency} {numberWithCommas(product.sale_price)}
                                </span>
                                <span className="price2 profile-pg-wl-sgl-product-reg-price2">
                                  {config.currency} {numberWithCommas(product.actual_price)}
                                </span>
                                {product.on_sale && (
                                  <span className="yt-product-off text-center p-1">
                                    {percentageValue < 1
                                      ? 'sale'
                                      : ' ' +
                                      parseInt(
                                        ((product.actual_price - product.sale_price) /
                                          product.actual_price) *
                                        100
                                      ) +
                                      '% Off'}
                                  </span>
                                )}
                                {product.on_sale && false ? (
                                  <span className="profile-yt-sgl-product-off text-center p-1">
                                    Sale
                                  </span>
                                ) : (
                                  <span className="text-center p-1" />
                                )}

                              </>
                            )}
                            {!product.on_sale && (
                              <span className="price1 profile-pg-wl-sgl-product-sale-price">
                                {config.currency} {numberWithCommas(product.actual_price)}
                              </span>
                            )}
                          </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                }

                return product?.product_variants?.map(
                  (value, index) =>
                    value.is_master && (
                      <Col
                        md={3}
                        lg={3}
                        className="px-2 col-xxl-3 yt-cm-wl-col"
                      >
                        <div className="product profile-pg-wl-sgl-product-cpnt text-center mb-4">
                          <div
                            className="w3-ripple"
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              src={
                                value.images.length > 0
                                  ? value.images[0].original_image
                                  : product.images[0].original_image
                              }
                              className="yt-product-bg-image img-fluid"
                              alt="bigbargains"
                              onClick={() => history.push('/shop/' + value.id)}
                            />
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div
                                className="text-left px-4 mt-2"
                                style={{
                                  opacity: product.total_ratings > 0 ? 1 : 0,
                                }}
                              >
                                <span>
                                  {product.average_rating}{' '}
                                  <IoIosStar className="rating-star-icon" />{' '}
                                  <span className="yt-product-rating ">
                                    | {product.total_ratings}
                                  </span>
                                </span>
                              </div>
                              <div className="text-right pr-2">
                                <FavouriteProductSet
                                  onDataId={index}
                                  onPageType="shop"
                                  isFav={product.is_wishlisted}
                                  onClick={() =>
                                    product.is_wishlisted
                                      ? removeFromWishlist(product)
                                      : addToWishlist(product)
                                  }
                                />
                              </div>
                            </div>
                            <div onClick={() => history.push('/shop/' + value.id)}>
                              <div className="mt-2 profile-pg-wl-sgl-product-title text-left px-4 pb-0 mb-0">
                                {product.name}
                              </div>
                              <div className="price profile-pg-wl-sgl-price-wrap text-left px-4 mb-3">
                                {value.on_sale && (
                                  <>
                                    <span className="price1 profile-pg-wl-sgl-product-sale-price">
                                      {config.currency} {numberWithCommas(value.sale_price)}
                                    </span>
                                    <span className="price2 profile-pg-wl-sgl-product-reg-price2">
                                      {config.currency} {numberWithCommas(value.actual_price)}
                                    </span>
                                    {value.on_sale && (
                                      <span className="yt-product-off text-center p-1">
                                        {parseInt(
                                          ((value.actual_price - value.sale_price) / value.actual_price) *
                                          100
                                        )}
                                      %
                                      </span>
                                    )}
                                  </>
                                )}
                                {!value.on_sale && (
                                  <span className="price1 profile-pg-wl-sgl-product-sale-price">
                                    {config.currency} {numberWithCommas(value.actual_price)}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>
                      </Col>
                    )
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
function UserWhishList({ products, ...props }) {
  return Array.isArray(products) && products.length > 0 ? (
    <WhishListProductComponent products={products} {...props} />
  ) : (
    <NoWishlist />
  );
}
export default UserWhishList;
