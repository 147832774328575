import React from 'react';
import { Container, Button, Form, FormGroup, Input } from 'reactstrap';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router';

import language from '../../language/language';

import './css/index.scoped.css';

function CreatePassFields() {
  return (
    <div className="yt-cp-wrap">
      <h3 className="yt-cp-top-back-tag-name mb-4">
        {language.auth.createPassword}
      </h3>
      <h2 className="yt-cp-tag-line">{language.auth.headerText}</h2>
      <div className="yt-cp-form mb-4">
        <Form>
          <FormGroup>
            <img src={require('./images/key-icn.png')} className="yt-cp-icn" />
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
            />
            <FaEye className="yt-pas-eye-icn" />
            <span id="yt-signup-pass-error" className="yt-enter-pass" />
          </FormGroup>
          <FormGroup className="success">
            <img src={require('./images/key-icn.png')} className="yt-cp-icn" />
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
            />
            <FaEye className="yt-pas-eye-icn" />
            <span className="yt-pass-info d-block mb-2">
              {language.auth.passwordRule}
            </span>
            <span id="yt-reenter-pass-message" className="yt-reenter-pass">
              {language.auth.passwordChanged}
            </span>
          </FormGroup>
          <Button color="secondary yt-cp-btn" block>
            {language.auth.resetPassword}
          </Button>
        </Form>
      </div>
    </div>
  );
}

function CreatePasswordComponent() {
  const history = useHistory();
  return (
    <Container>
      <div className="yt-create-pw-wrap yt-cpp-mb-5 yt-cpp-mt-5">
        <div className="d-flex flex-wrap yt-cret-row">
          <div className="yt-cp-inner-content yt-cp-bg-banner yt-banner-col d-none">
            <div className="yt-cp-other-info">
              <p className="yt-cp-oi-tag-small">Fashion and Trend come togather</p>
              <h3 className="yt-cp-oi-tag-big">Upto 30% off</h3>
              <span className="yt-cp-bdr" />
              <Button
                color="secondary yt-cp-sp-now"
                onClick={() => history.push('/shop')}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div className="yt-cp-inner-content yt-form-col">
            <CreatePassFields />
          </div>
        </div>
      </div>
    </Container>
  );
}
export default CreatePasswordComponent;
