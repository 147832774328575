import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { AllModal } from '../..';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';
import classnames from 'classnames';

import NoAddress from './NoNotifications';
import commands from '../../../commands';
import UserState from '../../../redux/states/user';
import lang from '../../../language';
import './css/index.scoped.css';
import NoNotifications from './NoNotifications';
import { getDays } from '../../../utils';

function ExistNotifications(props) {

  const savedaddresslang = new lang('savedAddressesScreen');
  const [activeTab, setActiveTab] = useState('1');
  const [userNotifications, setUserNotifications] = useState([]);
  const [ShowETModal, setShowETModal] = useState(false);
  const [delRecords, setDelRecords] = useState([]);
  const [ShowADModal, setShowADModal] = useState(false);
  const [ShowDeleteNotificationModal, setDeleteNotificationModal] = useState(false);
  const [showDefaultAddressModal, setDefaultAddressmModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const state = useSelector((state) => state.logInReducer.loginData);

  useEffect(() => {
    setUserNotifications(props.notifications)
  }, [props])

  useEffect(() => {
    commands.user.getNotifications(onNotificationSuccess, onNotificationFailure);
  }, []);

  const onNotificationSuccess = () => {
    setUserNotifications(props.getNotifications());
  };

  const onNotificationFailure = () => { };

  const openDeleteNotificationModal = (x) => {
    setDelRecords(x);
    setDeleteNotificationModal(!ShowDeleteNotificationModal);

  };

  const handleNotifyRead = (type, id) => {
    props.getNotifications()
    commands.user.updateNotification(type, id)
    props.getNotifications()
  }

  const handleDeleteAll = () => {
    commands.user.deleteAllNotification(onNotificationSuccess, onNotificationFailure)
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      {Array.isArray(userNotifications) && userNotifications.length > 0 ? (
        <div className="">

          <AllModal
            deletedRecords={delRecords}
            setUserNotifications={() => onNotificationSuccess()}
            modalName="deleteNotifications"
            getNotifications={() => props.getNotifications()}
            isOpen={ShowDeleteNotificationModal}
            toggle={() => setDeleteNotificationModal(!ShowDeleteNotificationModal)}
          />


          <div className="profile-pg-inner-wrapper">
            <div className="profile-pg-sa-address-main-wrap">
              <div className="read-delete-buttons">
                <button className="read-delete-btn" onClick={() => handleNotifyRead('all', 0)}>Read All</button>
                <button className="read-delete-btn" onClick={() => handleDeleteAll()}>Delete All</button>
              </div>
              <ul className="pp-sa-list-none p-0 m-0 pp-sa-all-addres-list">
                {userNotifications.map((x) => {
                  return (
                    <li
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                      key={x.id}
                    >
                      <div
                        onClick={() => handleNotifyRead('single', x.id)}
                        className="profile-pg-address-list radius-10 profile-pg-mb-30"
                        style={!x.is_read && !x.is_read ? { backgroundColor: '#3b5998', cursor: 'pointer', color: '#ffffff' } : { cursor: 'default', backgroundColor: 'white' }}
                      >
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="pp-sa-img-wrap d-flex">
                            <img
                              src={require('./images/notify-icn.png')}
                              width="65"
                              height="65"
                            />
                            <div className="pp-sa-info-wrap pr-5">
                              <h2 className="pp-sa-type mt-0">
                                {/*x.address_type*/ capitalize(x.name)}
                              </h2>
                              <p
                                className="pp-sa-address mb-3"
                                style={{ overflow: 'hidden' }}
                              >
                                <span className="notification-title" style={!x.is_read && !x.is_read ? { color: '#ffffff' } : {}}>{x.title}</span>
                              </p>
                              <br />
                              <span className="notification-sub-title" style={!x.is_read && !x.is_read ? { color: '#ffffff' } : {}}>{x.message}</span>
                            </div>
                          </div>

                          <div className="pp-sa-action-wrap d-flex align-items-end justify-content-end">

                            <div className="pp-sa-delet text-right pl-3">
                              <p>{getDays(x.created_at)}</p>
                              {x.is_read && x.is_read ?
                                <img onClick={() => openDeleteNotificationModal(x)} className="delete-btn" src={require('./images/delete-icn.png')} />
                                :
                                ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <NoNotifications userData={state} />
      )}
    </>
  );
}

export default ExistNotifications;
