import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';

import ChangePasswordContent from './ChangePasswordContent';
import lang from '../../../language';
import './css/index.scoped.css';

function ChangePasswordSuccess() {
  const passwordchangelang = new lang('changePasswordScreen');
  const history = useHistory();
  const routeToProfile = () => {
    let path = '/profile';
    history.push(path);
  };
  return (
    <section className="profile-change-password-main-wrapper  mb-5 pb-5">
      <Container>
        <Row>
          <Col md={12}>
            <div className="pageroute profile-pg-breadcrumbs my-3">
              <Link to="/">
                <span
                  className="profile-pg-home w3-hover-opacity w3-ripple"
                  style={{ cursor: 'default' }}
                >
                  Home
                </span>
              </Link>
              {' > '}
              <Link to="/profile">
                <span
                  className="profile-pg-home w3-hover-opacity w3-ripple"
                  style={{ cursor: 'default' }}
                >
                  Profile
                </span>
              </Link>
              {' > '}
              <span className="profile-pg-current">Change Password</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="cp-pg-inner-wrap password-success bg-white radius-10 cart-pg-mb-30 mt-5">
              <div className="cp-pg-empty-main-wrap text-center">
                <img
                  alt="Password Icon"
                  src={require('./images/passwordchanged-icn.png')}
                  className="img-fluid"
                  width="170"
                  height="212"
                />
                <div className="cp-wrap my-5">
                  <h2 className="cp-profile-ttl mt-0 mb-3">
                    {passwordchangelang.get(
                      'passwordChangeSuccessful',
                      'Password changed successfully !'
                    )}
                  </h2>
                  <p className="empty-cart-text mb-0">
                    {passwordchangelang.get(
                      'passwordChangeSuccessfulMessage',
                      'You can now go back and continue browsing products'
                    )}
                    {passwordchangelang.get(
                      'enjoyShopping',
                      'Enjoy Shopping !'
                    )}
                  </p>
                </div>
                <Button
                  color="secondary cp-go-to-back-btn py-3 "
                  onClick={routeToProfile}
                >
                  {passwordchangelang.get('gotoProfile', 'GO TO PROFILE')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
function ChangePasswordComponent() {
  //const [ShowChangePassword, setShowChangePassword] = useState(true);
  // const ShowChangePassword = true;
  const [ShowChangePassword, setShowChangePassword] = useState(true);
  const changeStatus = (status) => {
    if (status) {
      setShowChangePassword(!status);
    }
  };
  return (
    <>
      {ShowChangePassword ? (
        <ChangePasswordContent changeStatus={changeStatus} />
      ) : (
        <ChangePasswordSuccess />
      )}
    </>
  );
}
export default ChangePasswordComponent;
