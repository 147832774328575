import React from 'react';
import { Row } from 'reactstrap';

import langg from '../../language';

import './css/index.scoped.css';

function AppStoreBanner() {
  const lang = new langg('common');

  return (
    <div className="yt-appstore-component appstore">
      <div>
        <Row>
          <div className="textbox7 yt-asc-inner text-center px-3">
            <div className="yt-asc-title">
              {lang.get('downloadapp', 'Download the App for Free')}
            </div>
            <div className="yt-asc-images d-flex flex-wrap align-items-center justify-content-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.engineer.bigbargains"
                target="_blank"
                className="stores w3-ripple img-fluid yt-asc-img"
              >
                <img
                  src={require('./images/g10.png')}
                  alt="bigbargains"
                  className="img-fluid w3-hover-shadow myApps"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/bigbargains/id1592275371"
                target="_blank"
                className="stores w3-ripple img-fluid yt-asc-img"
              >
                <img
                  src={require('./images/Group3640.png')}
                  alt="bigbargains"
                  className="img-fluid w3-hover-shadow myApps"
                />
              </a>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default AppStoreBanner;
