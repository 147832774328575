import React from 'react';
import { Button } from 'reactstrap';

import lang from '../../../language';
import './css/index.scoped.css';
import { useHistory } from 'react-router-dom'

function NoNotifications(props) {
  const saveaddresslang = new lang('savedAddressesScreen');

  const history = useHistory()
  return (
    <div className="profile-pg-inner-wrap profile-pg-inner-no-add p-3 bg-white radius-10 profile-pg-mb-30">
      <div className="profile-pg-sa-no-address-main-wrap text-center">
        <img src={require('./images/notification-icn.png')} className="img-fluid mb-5" />
        <div className="pp-sa-no-add-wrap mt-2 mb-5">
          <h2 className="pp-na-ttl mt-0 mb-3">
            {saveaddresslang.get('noNotificationYet', 'No notifications yet !')}
          </h2>
          <p className="pp-na-text mb-0">
            {saveaddresslang.get(
              'noNotificationMessage',
              'Browse for products or check out our latest additions. '
            )}
          </p>
        </div>
        <Button
          color=" pp-no-addr-btn py-3"
          onClick={() => history.push('/shop')}
        >
          {saveaddresslang.get('continueShopping', 'Continue Shopping')}
        </Button>
      </div>
    </div>
  );
}

export default NoNotifications;
