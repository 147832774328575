import Home from './home';
import ContactUs from './contactus';
import AboutUs from './aboutus';
import Reviews from './reviews';
import Products from './products';
import ProductDetails from './productdetails';
import HelpCenter from './helpcenter';
import LoginPage from './login';
import ForgotPassword from './forgotpassword';
import CreatePassword from './createpassword';
import ProfilePage from './profile';
import CartPage from './cart';
import OrderPlacedPage from './orderplaced';
import TransactionFailedPage from './transactionfailed';
import ChangePasswordPage from './changepassword';
import OrderDetailPage from './orderdetails';
import SignUpConfirm from './signUpConfirmation';
import Payment from './payment';
import Market from "./market";

export default {
  Home,
  Products,
  ProductDetails,
  ContactUs,
  AboutUs,
  HelpCenter,
  LoginPage,
  ForgotPassword,
  CreatePassword,
  ProfilePage,
  CartPage,
  OrderPlacedPage,
  TransactionFailedPage,
  ChangePasswordPage,
  OrderDetailPage,
  SignUpConfirm,
  Payment,
  Reviews,
  Market
};
