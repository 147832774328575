import React, { useRef } from "react";
import "./css/index.scoped.css";
import { Button, Container } from "reactstrap";
import Carousel from "react-elastic-carousel";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import Ripple from "react-ripples";
import { useHistory } from "react-router";
import langg from "../../language";
import { LazyLoadImage } from "react-lazy-load-image-component";

function TitleBar(props) {
  const history = useHistory();
  const lang = new langg("common");
  if (props.name != undefined) {
    return (
      <div className="yt-produstslider-info d-flex justify-content-between align-items-center ml-4">
        <h4 className="yt-comonent-top-title my-0">{props.name}</h4>
        <div className="yt-comonent-link">
          <Button
            color=" yt-component-more px-0"
            onClick={() => history.push("/shop")}
          >
            {lang.get("viewAll", "View All")}
          </Button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function CollectionCard(props) {
  const history = useHistory();
  let carousel = useRef();
  let Breakpoints = [
    { width: 320, itemsToShow: 2 },
    { width: 730, itemsToShow: 4 },
    { width: 1300, itemsToShow: 5 },
  ];
  const collections = props.collections;
  return (
    Array.isArray(collections) &&
    collections.length > 0 && (
      <section className="hp-collection-slider">
        <Container>
          <div className="yt-main-wrap">
            <TitleBar name={props.name} />
            <div className="product yt-collection-component yt-collection-wrap  py-4 ">
              <IoIosArrowDropleft
                className="yt-slider-left img-fluid"
                onClick={() => carousel.slidePrev()}
              />
              <IoIosArrowDropright
                className="yt-slider-right img-fluid"
                width="20"
                height="20"
                onClick={() => carousel.slideNext()}
              />
              <Carousel
                itemsToShow={5}
                pagination={false}
                showArrows={false}
                ref={(ref) => (carousel = ref)}
                breakPoints={Breakpoints}
                className="collection-card-slider"
              >
                {collections.map((item, idx) => (
                  <Ripple key={idx}>
                    <div
                      className="yt-collection-item w3-ripple"
                      onClick={() =>
                        history.push(`/shop?category_id[]=${item.id}`)
                      }
                    >
                      {" "}
                      <div className="content-overlay" />
                      {/* <img src={item.image || '/images/thumb/missing.png'} className="img-fluid yt-coll-img" alt="bigbargains" /> */}
                      <LazyLoadImage
                        className="img-fluid yt-coll-img"
                        alt={item.name}
                        effect="blur"
                        width="100%"
                        src={item.image || "/images/thumb/missing.png"}
                      />
                      <div className="yt-collection-title">{item.name}</div>
                      <div className="content-details fadeIn-bottom">
                        <h3>{item.name}</h3>
                      </div>
                    </div>
                  </Ripple>
                ))}
              </Carousel>
            </div>
          </div>
        </Container>
      </section>
    )
  );
}

export default CollectionCard;
