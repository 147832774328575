import React from "react";
import "./css/index.scoped.css";
import commands from "../../commands";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function HeroBanner() {
  const mybanner = commands.cache.getWebPositionedBanners({ position: 1 });

 
  return (
    <Carousel
      showStatus={false}
      showArrows={true}
      autoPlay={true}
      interval={4500}
      infiniteLoop={true}
      className="carousel"
    >
      {mybanner[0]?.images?.map((myImage, index) => (
        <section
          className={myImage?.url && "hoverMe"}
          style={{
            backgroundImage: `url(${myImage?.original_image})`,
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "relative",
          }}
          onClick={() => {
            myImage?.url && (window.location.href = myImage?.url);
          }}
          key={index}
        >
          <div className="yty-bg-3banner">
            <div className="banner-wrapper yt-full-width" />
          </div>
        </section>
      ))}
    </Carousel>
  );
}

export default HeroBanner;
