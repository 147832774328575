import React, { useEffect, useState, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { FaChevronRight, FaChevronDown, FaRegHeart } from "react-icons/fa";
import { AiFillCaretRight } from "react-icons/ai";
import { RiHeadphoneLine } from "react-icons/ri";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { AllModal } from "../../components";
import SearchData from "./SearchData";
import { get, getProducts } from "../../Barriers/apiHelper";
import { passCategoryData } from "../../redux/actions/cartActions";
import commands from "../../commands";
import langg from "../../language";
import "./css/index.scoped.css";
import { VscChromeClose } from "react-icons/vsc";

function cleanArray(actual) {
  let newArray = new Array();
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

function Header(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentPageArray = props.onProps.location.pathname;
  const currentpagestr = cleanArray(currentPageArray.split("/"));
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const state = useSelector((state) => state.logInReducer);
  const cartState = useSelector((state) => state.cartReducer);
  const [category, setCategoryData] = useState([]);
  const [SearchDropDown, setSearchDropDown] = useState(false);
  const [quickResults, setQuickResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [refreshMenu, setRefreshMenu] = useState(true);
  const [desktopToggle, setDesktopToggle] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [menuCategory, setMenuCategory] = useState();
  const [mCategory, setMCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [isShopMenu, setIsShopMenu] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [headerScrolled, setHeaderScrolled] = useState(false);

  const lang = new langg("header");

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      setHeaderScrolled(false);
    } else if (window.scrollY > 250) {
      setHeaderScrolled(true);
    }
  };

  let currentPageActive = "";
  if (currentpagestr.length == 0) {
    if (!isShopMenu) {
      currentPageActive = "/";
    } else {
      currentPageActive = "shop";
    }
  } else {
    if (!isShopMenu) {
      currentPageActive = currentpagestr.shift();
    } else {
      currentPageActive = "shop";
    }
  }

  const toggle = () => setIsOpen(!isOpen);

  const onHandleClickShop = () => {
    setIsShopMenu(true);
  };

  const [ShowNotifyMeModal] = useState(false);

  const routeToAll = (value) => {
    if (value !== undefined) {
      let path = "/" + value;
      history.push(path);
    } else {
      let path = "/";
      history.push(path);
    }
  };

  const handleCartMenu = () => {
    setCartMenu(true);
    routeToAll("cart");
  };

  const isLoggedIn =
    state.guest_user === false && typeof state.loginData?.user === "object";

  const callCategoriesUuid = (uuid) => {
    get(`/categories?with_subcategory=true&uuid=${uuid}`)
      .then((res) => {
        dispatch(passCategoryData(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callCategoriesToken = ({ access_token, token_type }) => {
    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token_type} ${access_token}`,
      },
    };
    getProducts(`/categories?with_subcategory=true`, header)
      .then((res) => {
        dispatch(passCategoryData(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function quickSearch() {
    if (searchQuery != "") {
      commands.products
        .getQuickSearch({ query: searchQuery })
        .then((results) => {
          setQuickResults(results);
        });
    }
  }
  function getRecentSearch() {
    commands.products.getRecentSearches().then((results) => {
      setRecentSearches(results);
    });
  }


  useEffect(() => {
    if (state.loginData?.user?.name) {
      setName(state.user?.name);
    }
    if (Object.keys(state.loginData).length > 0 && refreshMenu) {
      callCategoriesToken(state.loginData.token);
    } else if (state.uuId?.length > 0 && refreshMenu) {
      callCategoriesUuid(state.uuId);
    } else {
      setCategoryData(cartState.categotyData || []);
    }
    setRefreshMenu(false);
  }, [props, category, cartState.categotyData, refreshMenu]);

  useEffect(() => {
    let qparams = new URLSearchParams(window.location.search);
    let selectedCategoryMenu = qparams.get("category_id[]");
    let selectedSubCategoryMenu = qparams.get("sub_category_id[]");
    if(selectedCategoryMenu != undefined){
      setMCategory(parseInt(selectedCategoryMenu))
      setMenuCategory(parseInt(selectedCategoryMenu));
      setSubCategory(parseInt(selectedSubCategoryMenu));
    } else{
      setMenuCategory(cartState.categotyData[0]?.id);
      setMCategory(cartState?.categotyData[0]?.id);
    }
  }, [window.location.search]);

  function search() {
    if (searchQuery != "") {
      history.push(`/shop?filter_query=${searchQuery}`);
    }
  }
  const makeaction = () => {
    setShowSearchBar(!showSearchBar);
    setSearchDropDown(!SearchDropDown);
    if (searchQuery === "") {
      getRecentSearch();
    }
  };
  return (
    <header
      className={
        headerScrolled ? "yt-main-header myHeader2" : "yt-main-header myHeader1"
      }
    >
      {ShowNotifyMeModal ? <AllModal modalName="notifyme" /> : ""}
      <AllModal
        modalName="logout"
        isOpen={showLogout}
        toggle={() => setShowLogout(!showLogout)}
      />
      <div className={showSearchBar ? "logocontainer" : "logocontainer d-none"}>
        <Container>
          <Row className="align-items-center">
            <div className="col-12 order-sm-1 yt-head-hide-srch-col">
              <div className="searchcontainer py-2 header-search-field-wrap">
                <input
                  type="text"
                  placeholder="Search Products . . ."
                  className="searchinput"
                  onClick={() => setSearchDropDown(true)}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      search();
                      setSearchDropDown(false);
                    } else {
                      quickSearch();
                    }
                  }}
                  // onFocus={() => {
                  //   setSearchDropDown(true);
                  //   getRecentSearch();
                  // }}
                />
                <img
                  src={require("./images/magnifying-glass@3x.png")}
                  alt="search"
                  className="searchicon w3-ripple w3-hover-opacity"
                  onClick={() => search()}
                />
                <VscChromeClose
                  className="searchicon w3-ripple w3-hover-opcity"
                  onClick={() => setShowSearchBar(false)}
                  style={{ right: "0", left: "auto" }}
                />
                {SearchDropDown && searchQuery != "" && (
                  <SearchData
                    hideSearch={() => makeaction()}
                    results={quickResults}
                    isQuickResults={true}
                  />
                )}
                {SearchDropDown &&
                  searchQuery == "" &&
                  Array.isArray(recentSearches) &&
                  recentSearches.length > 0 && (
                    <SearchData
                      hideSearch={() => makeaction()}
                      results={recentSearches}
                    />
                  )}
              </div>
            </div>
          </Row>
          <div className="d-none yt-head-mb-search-panel">
            {SearchDropDown && searchQuery != "" && (
              <SearchData
                hideSearch={() => makeaction()}
                results={quickResults}
                isQuickResults={true}
                isMobile={true}
              >
                <div className="yt-mb-header-search-bar-wrap">
                  <input
                    type="text"
                    placeholder="Search..."
                    className=""
                    onClick={() => setSearchDropDown(true)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        search();
                        setSearchDropDown(false);
                      } else {
                        quickSearch();
                      }
                    }}
                    onFocus={() => {
                      setSearchDropDown(true);
                      getRecentSearch();
                    }}
                    autoFocus={true}
                  />
                </div>
              </SearchData>
            )}
            {SearchDropDown && searchQuery == "" && (
              <SearchData
                hideSearch={() => makeaction()}
                results={recentSearches}
                isMobile={true}
              >
                <div className="yt-mb-header-search-bar-wrap">
                  <input
                    type="text"
                    placeholder="Search..."
                    className=""
                    onClick={() => setSearchDropDown(true)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        search();
                        setSearchDropDown(false);
                      } else {
                        quickSearch();
                      }
                    }}
                    onFocus={() => {
                      setSearchDropDown(true);
                      getRecentSearch();
                    }}
                    autoFocus={true}
                  />
                </div>
              </SearchData>
            )}
          </div>
        </Container>
      </div>

      <div
        className={showSearchBar ? "logocontainer d-none" : "logocontainer "}
      >
        <Container>
          {/* <Row className="align-items-center">
            <Col className="yt-head-col">
              <div className="supportbox">
                <div className="support">
                  <RiHeadphoneLine className="supportimage" />
                  <div className="supportnumber">
                    <span className="number">CALL US : <a  href="tel:1800 419 9555">1800 419 9555</a></span>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="yt-head-col">
              <div className="cartcontainer py-2">
                English INR
              </div>
            </Col>
          </Row> */}
          <div className="d-none yt-head-mb-search-panel">
            {SearchDropDown && searchQuery != "" && (
              <SearchData
                hideSearch={() => makeaction()}
                results={quickResults}
                isQuickResults={true}
                isMobile={true}
              >
                <div className="yt-mb-header-search-bar-wrap">
                  <input
                    type="text"
                    placeholder="Search..."
                    className=""
                    onClick={() => setSearchDropDown(true)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        search();
                        setSearchDropDown(false);
                      } else {
                        quickSearch();
                      }
                    }}
                    onFocus={() => {
                      setSearchDropDown(true);
                      getRecentSearch();
                    }}
                    autoFocus={true}
                  />
                </div>
              </SearchData>
            )}
            {SearchDropDown && searchQuery == "" && (
              <SearchData
                hideSearch={() => makeaction()}
                results={recentSearches}
                isMobile={true}
              >
                <div className="yt-mb-header-search-bar-wrap">
                  <input
                    type="text"
                    placeholder="Search..."
                    className=""
                    onClick={() => setSearchDropDown(true)}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        search();
                        setSearchDropDown(false);
                      } else {
                        quickSearch();
                      }
                    }}
                    onFocus={() => {
                      setSearchDropDown(true);
                      getRecentSearch();
                    }}
                    autoFocus={true}
                  />
                </div>
              </SearchData>
            )}
          </div>
        </Container>
      </div>
      <div className="menucontainer yt-main-menu">
        <Container>
          <Row className="new-nav alirn-items-center">
            <Col xs={12} sm={8} lg={3} className="yt-head-col">
              <div className="d-flex align-items-center">
                <img
                  src={require("./images/menuicon.png")}
                  alt="search"
                  className="menuicon d-md-none w3-ripple"
                  onClick={toggle}
                />
                {/* <Link to="/"> */}
                <div
                  className="logobox"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                >
                  <img
                    src={require("./images/Logo@3x.png")}
                    className="logoimage"
                    alt="bigbargains"
                  />
                </div>
                {/* </Link> */}
                <Dropdown
                  isOpen={desktopToggle}
                  toggle={() => {
                    setDesktopToggle(!desktopToggle);
                    if (desktopToggle) {
                      setIsShopMenu(false);
                    }
                  }}
                  className="cm-drop-down-wrap active AAAA px-2"
                  onMouseEnter={() => {
                    setDesktopToggle(!desktopToggle);
                    if (desktopToggle) {
                      setIsShopMenu(false);
                    }
                    onHandleClickShop();
                  }}
                  onMouseLeave={() => {
                    setDesktopToggle(!desktopToggle);
                    if (desktopToggle) {
                      setIsShopMenu(false);
                    }
                  }}
                >
                  <DropdownToggle
                    id={desktopToggle ? "shopMenu2" : "shopMenu"}
                    className={classnames({
                      active: currentPageActive === "shop",
                    })}
                    onClick={onHandleClickShop}
                  >
                    {lang.get("shopByCategory", "Shop By Category")}
                    {!desktopToggle ? (
                      <FaChevronRight className="head-dropdown-arrow-icn" />
                    ) : (
                      <FaChevronDown className="head-dropdown-arrow-icn" />
                    )}
                  </DropdownToggle>

                  <DropdownMenu right className="cm-big-drop-menu">
                    <Row>
                      <Col>
                        <div className="left-menu-items-container">
                          {category.length > 0 &&
                            category.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className={classnames("left-menu-item ", {
                                    active: mCategory === item.id,
                                  })}
                                  onMouseEnter={() => {
                                    setMenuCategory(item.id);
                                    setMCategory(item.id);
                                  }}
                                >
                                  <img
                                    src={item.image}
                                    className="img-fluid categoryimage"
                                  />
                                  <span className="ml-2">{item.name}</span>
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                      <Col className="right-menu-items-container">
                        <div>
                          {category.length > 0 &&
                            category.map((item) => {
                              return (
                                item.id === menuCategory && (
                                  <Fragment key={item.id}>
                                    {item?.sub_categories?.length > 0 &&
                                      item?.sub_categories.map((sub) => {
                                        return (
                                          <div
                                            key={sub.id}
                                            className={classnames(
                                              "right-menu-item",
                                              { active: subCategory == sub.id }
                                            )}
                                            onClick={() =>
                                              history.push(
                                                `/shop?&category_id[]=${
                                                  item.id
                                                }&sub_category_id[]=${sub.id}`
                                              )
                                            }
                                          >
                                            {sub.name}
                                          </div>
                                        );
                                      })}
                                  </Fragment>
                                )
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
            <div className="yt-head-col col-12 col-lg-5 order-sm-1">
              <Navbar light expand="md">
                <Collapse navbar>
                  <Nav className="mr-auto" navbar>
                    <NavItem className="nav-menu px-2">
                      <NavLink
                        className={classnames({
                          active: currentPageActive === "/",
                        })}
                        onClick={() => {
                          routeToAll();
                        }}
                      >
                        {lang.get("home", "Home")}
                      </NavLink>
                    </NavItem>
                    <NavItem className="px-2">
                      <NavLink
                        className={classnames({
                          active: currentPageActive === "shop",
                        })}
                        onClick={() => {
                          // routeToAll('shop?page=1&per_page=12');
                          routeToAll("shop?page=1&per_page=12");
                          // window.location.assign("/shop?page=1&per_page=12");
                        }}
                      >
                        {lang.get("newArrivals", "New Arrivals")}
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav-menu px-2">
                      <NavLink
                        className={classnames({
                          active: currentPageActive === "aboutus",
                        })}
                        onClick={() => {
                          routeToAll("aboutus");
                        }}
                      >
                        {lang.get("aboutus", "About Us")}
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-menu px-2">
                      <NavLink
                        className={classnames({
                          active: currentPageActive === "contactus",
                        })}
                        onClick={() => {
                          routeToAll("contactus");
                        }}
                      >
                        {lang.get("contactus", "Contact Us")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            <Col xs={12} sm={4} lg={3} className="yt-head-col">
              <div className="cartcontainer">
                <span className="d-inline-block yt-cart-icn-mainwp mr-3">
                  <img
                    src={require("./images/magnifying-glass@3x.png")}
                    alt="search"
                    className="carticon w3-ripple"
                    onClick={() => {
                      makeaction();
                    }}
                  />
                </span>
                <span className="d-inline-block yt-cart-icn-mainwp mr-3">
                  <FaRegHeart
                    className="favoriteicon w3-ripple"
                    onClick={() => {
                      routeToAll("profile/wishlist");
                    }}
                  />
                  <span
                    className="w3-green headerIcon"
                    style={{
                      opacity: state.user?.wishlists_count > 0 ? 1 : 0,
                      position: "absolute",
                      marginTop: "-3%",
                      marginLeft: "-4%",
                      borderRadius: "50%",
                      padding:
                        state.user?.wishlists_count > 9 ? "2px 2px" : "2px 4px",
                      fontSize: "10px",
                    }}
                  >
                    {state.user?.wishlists_count > 9
                      ? "9+"
                      : state.user?.wishlists_count}
                  </span>
                </span>
                <span className="d-inline-block yt-cart-icn-mainwp mr-3">
                  <img
                    src={
                      cartMenu
                        ? require("./images/shopping-red-cart@3x.png")
                        : require("./images/shopping-cart@3x.png")
                    }
                    alt="cart"
                    className="carticon w3-ripple"
                    onClick={() => {
                      handleCartMenu();
                    }}
                  />
                  <span
                    className="w3-green headerIcon"
                    style={{
                      opacity: cartState.cart?.order_items?.length > 0 ? 1 : 0,
                      position: "absolute",
                      marginTop: "-3%",
                      marginLeft: "-4%",
                      borderRadius: "50%",
                      padding:
                        cartState.cart?.order_items?.length > 9
                          ? "2px 2px"
                          : "2px 4px",
                      fontSize: "10px",
                    }}
                  >
                    {cartState.cart?.order_items?.length > 9 ? (
                      <center>9+</center>
                    ) : (
                      cartState.cart?.order_items?.length
                    )}
                  </span>
                </span>

                {!commands.user.isLoggedIn() ? (
                  <div
                    className="loginbutton w3-ripple"
                    onClick={() => {
                      routeToAll("login");
                    }}
                  >
                    <span>{lang.get("loginSignup", "Login / Signup")}</span>
                    <span className="d-none yt-head-lgn-btn">Login</span>
                  </div>
                ) : (
                  <div
                    className="userbox w3-ripple d-flex align-items-center"
                    onClick={() => props.onProps.history.push("/profile")}
                  >
                    <span>
                      <img
                        src={
                          state.user?.profile_picture
                            ? state.user?.profile_picture
                              ? state.user?.profile_picture
                              : require("./images/user.png")
                            : require("./images/user.png")
                        }
                        alt="user"
                        className="usericon"
                      />
                    </span>
                    <span className="uname">{name.split(" ")[0]}</span>
                    <AiFillCaretRight
                      style={{ marginLeft: "10px", color: "#8b8f95" }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <MobileSideNav
        isOpen={isOpen}
        toggle={toggle}
        setSearchDropDown={setSearchDropDown}
        setShowLogout={setShowLogout}
        currentPageActive={currentPageActive}
        category={category}
      />
    </header>
  );
}

function MobileSideNav(props) {
  const isOpen = props.isOpen;
  const toggle = props.toggle;
  const setSearchDropDown = props.setSearchDropDown;
  const authState = useSelector((state) => state.logInReducer);
  const history = useHistory();
  const setShowLogout = props.setShowLogout;
  const [showShopMenu, setShowShopMenu] = useState(false);
  const currentPageActive = props.currentPageActive;
  const category = props.category;
  const lang = new langg("header");

  const routeToAll = (value) => {
    if (value !== undefined) {
      let path = "/" + value;
      history.push(path);
    } else {
      let path = "/";
      history.push(path);
    }
  };

  const isLoggedIn =
    authState.guest_user === false &&
    typeof authState.loginData?.user === "object";

  const user = authState?.user;

  return (
    <div
      className={isOpen ? "yt-only-mobile-vw tr" : "yt-only-mobile-vw "}
      style={{ cursor: "default" }}
    >
      <div className="yt-mobile-side-nav-wrap">
        <div className="yt-mobile-side-inner-content">
          <div className="yt-inner-cnt-logo">
            <div className="logobox-mb">
              <img
                src={require("./images/Logo@3x.png")}
                className="logoimage"
                alt="bigbargains"
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="yt-inner-cnt">
            {!isLoggedIn ? (
              <div
                className="yt-mb-user-profile d-flex"
                onClick={() => {
                  history.push("/login");
                  toggle();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="yt-mb-nav-login-btn">
                  {lang.get("loginSignup", "Login / Signup")}
                </div>
              </div>
            ) : (
              <div
                className="yt-mb-user-profile d-flex"
                onClick={() => {
                  history.push("/profile");
                  toggle();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="yt-header-user-img" />
                <div className="yt-head-user-det">
                  <div className="yt-head-name">{user.name.split(" ")[0]}</div>
                  <div className="yt-head-email">{user.email}</div>
                </div>
              </div>
            )}
            <div className="yt-mb-innet-search">
              <div className="yt-mb-side-srch header-search-field-wrap">
                <img
                  src={require("./images/magnifying-glass@3x.png")}
                  alt="search"
                  className="searchicon"
                />
                <input
                  type="text"
                  placeholder={lang.get("searchProducts", "Search...")}
                  className="searchinput w3-hover-opacity"
                  onClick={() => {
                    setSearchDropDown(true);
                    toggle();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="yt-mobile-nav-content">
            <Navbar expand="md">
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          currentPageActive === "/" && showShopMenu === false,
                      })}
                      onClick={() => {
                        routeToAll();
                      }}
                    >
                      {lang.get("home", "Home")}
                    </NavLink>
                  </NavItem>
                  <Dropdown
                    isOpen={showShopMenu}
                    toggle={() => setShowShopMenu(!showShopMenu)}
                    nav
                    inNavbar
                    className="cm-drop-down-wrap"
                  >
                    <DropdownToggle nav>
                      {lang.get("shop", "Shop")}
                      {showShopMenu ? (
                        <FaChevronRight className="head-dropdown-arrow-icn" />
                      ) : (
                        <FaChevronDown className="head-dropdown-arrow-icn" />
                      )}
                    </DropdownToggle>
                    <DropdownMenu right className="cm-big-drop-menu">
                      <Row>
                        {category.length > 0 &&
                          category.map((x) => {
                            return (
                              <Col key={x.id} md={6} lg={3} key={x.id}>
                                <div className="cm-big-menu-inner">
                                  <div className="cm-big-menu-head w3-hover-opacity">
                                    <DropdownItem>{x.name}</DropdownItem>
                                    <DropdownItem divider />
                                  </div>
                                  {x.sub_categories.length > 0 ? (
                                    x.sub_categories.map((sub) => {
                                      return (
                                        <div
                                          key={sub.id}
                                          className={"w3-hover-opacity"}
                                        >
                                          <DropdownItem
                                            onClick={() => {
                                              history.push(
                                                `/shop?&category_id[]=${
                                                  x.id
                                                }&sub_category_id[]=${sub.id}`
                                              );
                                              toggle();
                                            }}
                                          >
                                            {sub.name}
                                          </DropdownItem>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </DropdownMenu>
                  </Dropdown>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentPageActive === "aboutus",
                      })}
                      onClick={() => {
                        routeToAll("aboutus");
                      }}
                    >
                      {lang.get("aboutus", "About Us")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentPageActive === "contactus",
                      })}
                      onClick={() => {
                        routeToAll("contactus");
                      }}
                    >
                      {lang.get("contactus", "Contact Us")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
          {isLoggedIn ? (
            <div
              className="yt-mobile-nav-content"
              style={{ cursor: "default" }}
            >
              <Navbar expand="md">
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/profile/wishlist");
                          toggle();
                        }}
                      >
                        {lang.get("wishlist", "Wishlist")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/profile/myorder");
                          toggle();
                        }}
                      >
                        {lang.get("myOrders", "My Orders")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/profile/notifications");
                          toggle();
                        }}
                      >
                        Notifications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/profile/saveaddresses");
                          toggle();
                        }}
                      >
                        {lang.get("savedAddresses", "Saved Addresses")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/profile/connectaccount");
                          toggle();
                        }}
                      >
                        {lang.get("connectedAccounts", "Connected Accounts")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/help-center");
                          toggle();
                        }}
                      >
                        {lang.get("helpCenter", "Help Center")}{" "}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          setShowLogout(true);
                          toggle();
                        }}
                      >
                        {lang.get("logout", "Logout")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          ) : (
            <div
              className="yt-mobile-nav-content"
              style={{ cursor: "default" }}
            >
              <Navbar expand="md">
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/help-center");
                          toggle();
                        }}
                      >
                        Help Center
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => {
                          history.push("/faq");
                          toggle();
                        }}
                      >
                        FAQ's
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          )}
        </div>
        <div className={"h-100"} onClick={toggle} />
      </div>
    </div>
  );
}

export default Header;
