import React from 'react';
import { Container, Button } from 'reactstrap';
import './css/index.scoped.css';

function PageLoadingBlock(props) {
  return (
    <>
      <section>
        <Container>
          <div className="trans-fl-pg-inner-wrap p-4 bg-white radius-10 trans-fl-pg-mb-80 mt-5">
            <div className="cart-pg-empty-main-wrap text-center py-5">
              <img
                src={require('./images/loadMe.gif')}
                className="img-fluid yt-transaction-cl-icn"
                width="170"
                height="212"
              />
              <div className="trans-fl-wrap ">
                <h2 className="trans-fl-ttl my-3" style={{ color: 'black' }}>
                  {props.title ? props.title : 'Oh Noes!, Error Occured'}
                </h2>
                <p className="trans-fl-text mb-0">
                  {props.message ? props.message : 'Your order was declined!'}
                </p>
              </div>
              {props.onButtonPress && (
                <Button
                  color=" trans-fl-btn py-3 px-5 mt-3"
                  onClick={props.onButtonPress}
                >
                  {props.buttonText ? props.buttonText : 'Go to Home'}
                </Button>
              )}
              <div className="mt-2" style={{ opacity: 0 }}>
                <Button color="link trans-fl-cancel-btn">
                  Cancel transaction?
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
export default PageLoadingBlock;
