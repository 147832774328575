import React from 'react';

import ExistNotifications from './ExistNotifications';
import './css/index.scoped.css';
import NoNotifications from './NoNotifications';

function Notifications(props) {

  return (
    <>
      {props.notifications && props.notifications.length > 0 ? (
        <ExistNotifications notifications={props.notifications} {...props} />
      ) : (
        <NoNotifications />
      )}
    </>
  );

}
export default Notifications;
