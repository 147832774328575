export default {
  name: "Arabic",
  direction: "rtl",
  common: {
    hello: "مرحبا",
    sale: "سعر مخفض",
    logoSubText: "Online Modern and elegant Islamic clothing",
    networkError: "خطأ في الشبكة!",
    exitApp: "الخروج من التطبيق",
    areYouSure: "هل أنت متأكد؟",
    cancel: "إلغاء",
    ok: "حسنا",
    noProductFound: "لم يتم العثور على منتجات",
    noProductFoundMessage: "حاول البحث باستخدام مصطلحات مختلفة أو تصفح الفئات الأخرى.",
    browseProducts: "تصفح المنتجات",
    guestHeader: "ليس لديك حساب؟/ تسجيل الدخول أولاً",
    guestMessage: "أنت بحاجة إلى حساب لتنفيذ هذا الإجراء.",
    signupLogin: "اشترك/ تسجيل الدخول",
    viewMore: "~View More",
    downloadapp: "~Download the App for Free",
    outOfStock: "~No Stock",
    addToCart: "~Add To Cart",
    placeYourOrder: "~Place Your Order",
    goToCart: "~Go To Cart"
  },
  header: {
    searchProducts: "~Search...",
    home: "~Home",
    market: "~Market",
    shop: "~Shop",
    aboutus: "~About Us",
    contactus: "~Contact Us",
    quickResults: "~Quick Results",
    recentSearches: "~Recent Searches",
    modifySearch: "~Try modifying your search to get relevant results.",
    noResults: "~No Results Found !",
    loginSignup: "~Login / Signup",
    wishlist: "~Wishlist",
    myOrders: "~My Orders",
    savedAddresses: "~Saved Addresses",
    connectedAccounts: "~Connected Accounts",
    helpCenter: "~Help Center",
    logout: "~Logout",
  },
  footer: {
    description: `~Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's
    standard dummy text ever since the 1500s`,
    about_us: "~About Us",
    delivery_and_returns: "~Delivery & Returns",
    contact_us: "~Contact Us",
    help_center: "~Help Centers",
    faq: "~FAQ's",
    t_and_c: "~Terms & Conditions",
    privacy_policy: "~Privacy Policy",
    copyrights: "~Copyright © 2020 Big Bargains, USA. All rights reserved.",
    useful_links: "~Useful Links",
    site_map: "~Site Map",
    quick_links: "~Quick Links",
    promotions: "~Promotions"
  },
  loginScreen: {
    login: "تسجيل الدخول",
    email: "البريد الإلكتروني",
    password: "الرمز السري",
    loginButton: "تسجيل الدخول",
    forgotPassword: "نسيت رمز المرور؟",
    continueVia: "أو متابعة عبر",
    facebook: "Facebook",
    google: "Google",
    apple: "Apple",
    agree: "بتسجيل الدخول أنت توافق على",
    termsAndCondition: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    skipAndContinue: "تخطي ومتابعة كضيف",
    invalidEmail: "البريد الالكتروني غير صحيح",
    invalidPassword: "أدخل كلمة مرور بأحرف أبجدية بإضافة على الاقل رمز ورقم(6 احرف كحد ادنى)",
    confirmAccount: "عذرا ، تحتاج إلى تأكيد حسابك أولا.",
    loginSuccessfull: "تم تسجيل الدخول بنجاح",
    subtitle: "ابدأ واكتشف أفضل العروض من حولك",
    signIn: "تسجيل الدخول",
    passwordRequiredError: "كلمة المرور مطلوبة."
  },
  signupScreen: {
    signUp: "اشترك",
    fullName: "الاسم الكامل",
    email: "البريد الإلكتروني",
    password: "الرمز السري",
    signUpButton: "اشترك",
    signUpVia: "الإشتراك عن طريق",
    facebook: "Facebook",
    google: "Google",
    apple: "Apple",
    agree: "بالتسجيل فإنك توافق على",
    termsAndCondition: "الشروط والأحكام",
    privacyPolicy: "سياسة الخصوصية",
    skipAndContinue: "تخطي ومتابعة كضيف",
    nameError: "يجب أن يحتوي الاسم على 3 أحرف كحد أدنى",
    emailError: "البريد الإلكتروني غير صحيح.",
    passwordError: "أدخل كلمة مرور تحتوي على الحروف الأبجدية من A إلى z والأرقام من 0 إلى 9 ورمز",
    loginSuccessfull: "تم تسجيل الدخول بنجاح",
    subtitle: "ابدأ واكتشف أفضل العروض من حولك"
  },
  signupOTPScreen: {
    signUp: "اشترك",
    subtitle: "ابدأ واكتشف أفضل العروض من حولك",
    otpSent: "تم إرسال OTP إلى بريدك الإلكتروني",
    verifyOTP: "OTP تحقق",
  },
  forgotPasswordScreen: {
    forgotPassword: "نسيت رمز المرور؟",
    email: "البريد الإلكتروني",
    enterOTP: "ادخل رمز التحقق",
    resendOTP: "OTP إعادة إرسال",
    sendOTP: "OTP أرسل",
    verifyOTP: "OTP تحقق",
    submit: "إدخال",
    otpVerify: "تأكيد رمز التحقق",
    skipAndContinue: "تخطي ومتابعة كضيف",
    continueToLogin: "استمر لتسجيل الدخول",
    resetLinkSend: "تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني المسجل.",
    registeredEmail: "أدخل بريدك الإلكتروني المسجل وسنرسل لك رمز التحقق لإعادة تعيين كلمة مرورك",
    otpVerified: "تم تأكيد رمز التحقق الخاص بك. يرجى المتابعة بعد ذلك وإعادة تعيين كلمة المرور الخاصة بك.",
    invalidEmail: "البريد الإلكتروني غير صحيح",
    OTPerror: "رمز التحقق غير صحيح",
    subtitle: "ابدأ واكتشف أفضل العروض من حولك",
    OTPSent: "تم إرسال OTP إلى معرف البريد الإلكتروني الخاص بك."
  },
  newPasswordScreen: {
    createNewPassword: "إنشاء كلمة مرور جديدة",
    newPassword: "أدخل كلمة مرور جديدة",
    confirmPassword: "تأكيد كلمة المرور الجديدة",
    resetPassword: "إعادة تعيين كلمة المرور",
    updatePassword: "تطوير كلمة السر",
    invalidPassword: "أدخل كلمة مرور تحتوي على الحروف الأبجدية من A إلى z والأرقام من 0 إلى 9 ورمز",
    passwordDosentMatch: "كلمة المرور وتأكيد كلمة المرور غير متطابقتين"
  },
  verifyAccountScreen: {
    verifyAccount: "التحقق من الحساب",
    email: "البريد الإلكتروني",
    enterOTP: "ادخل رمز التحقق",
    OTPVerify: "تأكيد رمز التحقق",
    submit: "إدخال",
    resendOTP: "إعادة ارسال رمز التحقق",
    OTPSend: "لقد أرسلنا رمز التحقق إلى عنوان بريدك الإلكتروني ، لتأكيد عنوان بريدك الإلكتروني.",
    registeredEmail: " أدخل بريدك الإلكتروني المسجل وسنرسل لك رابطًا لإعادة تعيين كلمة مرورك.",
    OTPVerified: "تم تأكيد رمز التحقق",
    invalidEmail: "البريد الإلكتروني غير صحيح",
    invalidOTP: "رمز التحقق غير صحيح"
  },
  homeScreen: {
    searchText: "بحث عن منتج",
    newProducts: "المنتجات الجديدة",
    viewAll: "عرض الكل",
    featuredCategory: "المنتجات المميزة",
    viewAllButton: "عرض الكل",
    recommendedProducts: "المنتجات المقترحة",
    collections: "~Collections",
    freeDeliveryTitle: "~Free Delivery",
    securePaymentTitle: "~Secure Payment",
    daysReturnTitle: "~60 Days Return",
    supportTitle: "~Support 24/7",
    freedeliverytext: "~Free Shipping on all order",
    securePaymentText: "~On every order over ₹100.00",
    daysReturnText: "~Guarantee under 7 days",
    supportText: "~Support online 24 hours a day"
  },
  searchScreen: {
    searchHere: "ابحث هنا..",
    recentSearches: "عمليات البحث الأخيرة",
    categories: "الفئات",
    subCategories: "الفئات الفرعية",
    products: "المنتجات",
    noResultFound: "لم يتم العثور على نتائج",
    modifySearch: "حاول تعديل البحث للحصول على نتائج ذات صلة."
  },
  exploreScreen: {
    explore: "استكشف"
  },
  wishListScreen: {
    itemRemove: "تمت الإزالة من قائمة الرغبات.",
    emptyWishlist: "قائمة رغباتك فارغة!",
    exploreMoreItem: "استكشف المزيد وحدد بعض المنتجات"
  },
  profileScreen: {
    profile: "الملف الشخصي",
    home: "~Home",
    profileUpdated: "تم تحديث ملفك الشخصي بنجاح",
    edit: "تعديل",
    wishlist: "قائمة الرغبات",
    myOrders: "طلباتي",
    savedAddresses: "العناوين المسجلة",
    shareWithFriend: "المشاركة مع صديق",
    connectedAccounts: "الحسابات المتصلة",
    changePassword: "تغيير كلمة المرور",
    contactUs: "اتصل بنا",
    helpCenter: "المساعدة",
    faq: "FAQs",
    notifications: "التنبيهات",
    logout: "تسجيل الخروج",
    logoutMessage: "هل أنت متأكد من أنك تريد تسجيل الخروج ",
    logIn: "تسجيل الدخول",
    takePicture: "التقاط صورة",
    addFromGallery: "اضافة من المعرض",
    guestUser: "حساب زائر",
    cancel: '~Cancel'
  },
  editProfileScreen: {
    editProfile: "تعديل الملف الشخصي",
    name: "الاسم",
    email: "البريد الإلكتروني",
    phno: "~Phone No",
    changePassword: "~Change Password",
    saveProfile: "حفظ الملف الشخصي",
    removePicture: "~Remove Picture",
    takePicture: "التقاط صورة",
    addFromGallery: "اضافة من المعرض",
    profileUpdated: "تم تحديث الملف الشخصي بنجاح"
  },
  myOrderScreen: {
    myOrders: "طلباتي",
    cancelOrder: "الغاء الطلب",
    cancelOrderMessage: "هل انت متأكد من إلغاء الطلب",
    cancelled: "تم الإلغاء",
    reviewSubmitted: "تم إرسال المراجعة بنجاح.",
    orderCancelled: "تم الغاء الطلب بنجاح.",
    noAnyOrder: "لا يوجد طلبات",
    noOrderText: "لم تقم بإدخال اي طلبات",
    browseProducts: "~Browse Products",
    submit: "ارسال",
    orderedOn: "تم الطلب في",
    confirmed: "تم التأكيد",
    quantity: " :العدد",
    orderNumber: "رقم الطلب",
    writeAReview: "اكتب تقييمك",
    yesConfirm: "نعم، تأكيد",
    rateAndReview: "التقييم والمراجعة",
    rateProduct: "قيم منتجنا",
    writeReview: "اكتب مراجعة مفصلة للمنتج ..",
    emptyReview: "لا يمكن أن تكون المراجعة فارغة.",
    cantreview: "~You can't review a product until you purchase it.",
    ok: "~Ok",
    writeDetailReview: "~Write detailed review for us ..",
    orderDeleted: "~Your order has been cancelled.",
    cancel: "~Cancel",
  },
  myOrderDetailScreen: {
    myOrders: "طلباتي",
    orderDetails: "~Order Details",
    writeAReview: "اكتب تقييمك",
    cancelOrder: "إلغاء الطلب",
    cancelOrderMessage: "هل انت متأكد من إلغاء الطلب",
    cancelled: "تم الإلغاء",
    submit: "ارسال",
    orderedOn: "تم الطلب في",
    confirmed: "تم التأكيد",
    trackingId: "رقم المتابعة",
    quantity: "العدد",
    orderNumber: "رقم الطلب",
    orderStatus: "حال الطلب",
    orderDate: "تاريخ الطلب",
    reviewSubmittedText: "تم إرسال المراجعة",
    writeAProductReview: "اكتب مراجعة للمنتج",
    yesConfirm: "نعم، تأكيد",
    rateAndReview: "التقييم والمراجعة",
    rateProduct: "قيم منتجنا",
    writeReview: "اكتب مراجعة مفصلة للمنتج ..",
    emptyReview: "لا يمكن أن تكون المراجعة فارغة.",
    reviewSubmitted: "تم إرسال المراجعة بنجاح.",
    orderItemCancelled: "تم الغاء الطلب بنجاح.",
    shippingAddress: "عنوان الشحن",
    phoneNumber: "رقم الهاتف"
  },
  savedAddressesScreen: {
    savedAddresses: "العناوين المسجلة",
    saveAddress: "~Save Address",
    addNewAddress: "اضافة عنوان جديد",
    name: "اسم",
    flatNumber: "شقة / بيت / فلة",
    addressLine1: "السطر الأول من العنوان",
    addressLine2: "السطر الثاني من العنوان",
    city: "مدينة",
    state: "ولاية",
    country: "دولة",
    pinCode: "الرمز",
    phoneNumber: "رقم الهاتف",
    selectDefaultAddress: "العنوان الافتراضي",
    noAddressSaved: "لا يوجد عناوين مسجلة",
    noAddressSavedMessage: "لا يوجد عناوين مسجلة في قائمة العناوين",
    addAnAddress: "اضافة عنوان",
    edit: "تعديل",
    delete: "حذف",
    deleteAddress: "حذف عنوان",
    deleteAddressMessage: "هل انت متأكد من حذف العنوان؟",
    yesDelete: "نعم، احذف",
    cancel: "~Cancel",
    defaultAddress: "العنوان الافتراضي",
    defaultAddressMessage: "هل انت متأكد من تغيير عنوانك الإفتراضي",
    newDefaultAddress: "عنوان افتراضي جديد",
    yes: "نعم",
    continue: "متابعة",
    defaultAddressChange: "~Your default address has been changed.",
    ok: "~Okay"
  },
  addNewAddressScreen: {
    addressUpdated: "تم تحديث العنوان بنجاح",
    addressAdded: "تم اضافة العنوان بنجاح",
    addNewAddress: "اضافة عنوان",
    editAddress: "تعديل عنوان",
    updateAddress: "~Update Address",
    name: "اسم",
    flatNumber: "شقة / بيت / فلة",
    addressLine1: "السطر الأول من العنوان",
    addressLine2: "السطر الثاني من العنوان",
    city: "مدينة",
    state: "ولاية",
    country: "دولة",
    pinCode: "الرمز",
    phoneNumber: "رقم الهاتف",
    saveAddress: "حفظ العنوان",
    checkout: "الدفع",
    billingAddress: "عنوان الدفع",
    shippingAddress: "عنوان الشحن",
    selectAddress: "تحديد العنوان",
    sameBillingAndShipping: "عنوان الدفع والشحن هما نفس العنوان",
    saveAddressSmall: "حفظ العنوان",
    continue: "متابعة"
  },
  connectedAccountsScreen: {
    connectedAccounts: "حسابات متصلة",
    googleAdded: "Google account added successfully.",
    googleRemoved: "Google account removed successfully.",
    facebookAdded: "Facebook account added successfully.",
    facebookRemoved: "Facebook account removed successfully.",
    connectedAs: "~Connected as",
    connectFacebookAccount: "~Connect Facebook Account",
    connectGoogleAccount: "~Connect Google Account",
    disconnect: "~Disconnect",
    disconnectMessage: "~Are you sure you want to disconnect your ",
    fromDisconnect: "~account from Big Bargains?",
    facebook: "Facebook",
    google: "Google",
    cancel: "~Cancel"
  },
  changePasswordScreen: {
    changePassword: "تغيير كلمة المرور",
    passwordDosentMatch: "كلمة المرور المدخلة غير مطابقة، يرجى المحاولة مرة اخرى!",
    enterCurrentPassword: "ادخال كلمة المرور الحالية",
    enterNewPassword: "ادخال كلمة مرور جديدة",
    reenterPassword: "ادخال كلمة مرور جديدة مرة اخرى",
    changePasswordButton: "تغيير كلمة المرور",
    passwordChangeSuccessful: "تم تغيير كلمة المرور بنجاح",
    passwordChangeSuccessfulMessage: "يمكن الرجوع والإستمرار بالتسوق",
    enjoyShopping: "تمتع بالتسوق",
    gotoProfile: "الذهاب للملف الشخصي",
    passwordValidationMessage: "أدخل كلمة مرور بأحرف أبجدية بإضافة على الاقل رمز ورقم(6 احرف كحد ادنى)",
    passwordMissmatch: "كلمات المرور غير متطابقة"
  },
  contactUsScreen: {
    contactUs: "اتصل بنا",
    name: "الاسم",
    email: "البريد الإلكتروني",
    phoneNumber: "رقم الهاتف",
    title: "العنوان",
    writeDetailDescription: "اكتب وصف تفصيلي",
    submit: "ارسال",
    detailSent: "تم ارسال التفاصيل بنجاح.",
    emptyDescription: "لا يمكن ترك الوصف فارغا.",
    purpose: "~Purpose of Contact",
    writeMessage: "~Write your message here...",
    send: "~Send",
    titleSuccessMessage:"~Message Sent Successfully",
    successMessage:"~We will connect with you soon regarding your query.",
    homeGo:"~Go To Home",
    shopGo:"~Go To Shop"
  },
  aboutUsScreen: {
    whatourcustomer: "~What our Customer say",
    readmore: "~Read More",
    readless: "~Read Less"
  },
  notificationScreen: {
    notifications: "تنبيهات",
    readAll: "قراءة الكل",
    delete: "حذف",
    BROWSE_FOR_PRODUCTS: "اكمال التسوق او الذهاب الى سلة التسوق",
    CONTINUE_SHOPPING: 'متابعة التسوق',
    YOUR_ORDER_SHIPPED: `تم رفع طلبك. سنقوم بتحديثك فور وصوله للتسليم`,
    NO_NOTIFICATIONS_YET: `لا يوجد تنبيهات جديدة`,
    THATS_ALL: `هذا كل شيء`,
    ORDER_SHIPPED: `تم الشحن`,
    giveAccess: "السماح بالدخول"
  },
  helpCenterScreen: {
    helpCenter: "المساعدة"
  },
  faqScreen: {
    faq: "FAQs"
  },
  productListingScreen: {
    sortBy: "ترتيب حسب",
    featured: "متميز",
    bestSelling: "الأكثر مبيعا",
    a_z: "ترتيب ابجدي",
    z_a: "ترتيب ابجدي عكسي",
    priceLowToHigh: "السعر، من الأقل الى الأعلى",
    priceHighToLow: "السعر من الأعلى الى الأقل",
    sort: "ترتيب",
    filter: "تصنيف",
    showing: "معروض",
    of: "~of",
    pages: "~pages",
    results: "نتائج",
    clear: "مسح",
    selectPriceRange: "تحديد السعر",
    discountedItems: "المنتجات المخفضة",
    search: "بحث",
    priceRange: "تحديد السعر",
    brands: "الماركة",
    tags: "التخصيص",
    category: "الفئة",
    cancel: "إلغاء",
    applyFilter: "تطبيق التغييرات",
    outOfStock: "~No Stock",
    addToCart: "~Add To Cart",
    placeYourOrder: "~Place Your Order",
    goToCart: "~Go To Cart",
    categories: "~Categories",
    allProducts: "~All Products",
    recommended: "~Recommended",
    byPopularity: "~By Popularity",
    latestProducts: "~Latest Products",
    category: "~Category",
    color: "~COLOR",
    size: "~SIZE",
    searchColors: "~Search Colors",
    newProducts: "المنتجات الجديدة",
    recommendedProducts: "المنتجات المقترحة",
  },
  productDescriptionScreen: {
    shippingNotAvailableAt: "الشحن غير متاح في",
    selectProductQuantity: "الرجاء تحديد عدد المنتج.",
    selectfromAvailableVariant: "يرجى الاختيار من بين المتغيرات المتاحة",
    subscribedSuccessfully: "تم الإشتراك بنجاح",
    sizeChart: "الرسم البياني",
    similarProduct: "منتجات مشابهة",
    description: "الوصف",
    outOfStock: "لا يوجد كمية",
    soldOut: "~Sold Out",
    inStock: "يوجد كمية",
    showLess: "عرض أقل",
    readMore: "عرض اكثر",
    buyNow: "الشراء الآن",
    subscribe: "اشتراك",
    addToCart: "اضافة الى السلة",
    updateCart: "تحديث السلة",
    gotoCart: "الذهاب الى السلة",
    totalPrice: "المجموع",
    price: "~PRICE",
    selectSubscriptionPeriodMessage: "يرجى تحديد مدة الإشتراك",
    selectSubscriptionPeriod: "تحديد مدة الإشتراك",
    selectSubscriptionPackageMessage: "تحديد باقة الإشتراك",
    selectSubscriptionPackage: "تحديد باقة الإشتراك",
    selectQuantity: "تحديد الكمية",
    quantity: "~Quantity",
    all: "الكل",
    reviews: "المراجعات",
    basedOn: "Based on",
    ratings: "~Ratings",
    ratingsAndReview: "المراجعات & التقييمات",
    productRatingCaps: "تقييم المنتج",
    productRatingSmall: "تقييم المنتج",
    okay: "حسنا",
    requestProcessed: "تمت معالجة الطلب",
    combinationNotAvailable: "Please select from the available variants. غير متوفر",
    notifyMe: "بلغني",
    itemOutOfStock: "هذا الطلب غير متوفر حاليا",
    youWillGetNotified: "سيتم إخطارك بمجرد عودة المنتج إلى المخزون.",
    month: "شهر",
    color:"~COLOR",
    size:"~SIZE",
    writeReview:"~Write Review",
    newProducts: "المنتجات الجديدة",
  },
  cartScreen: {
    cart: "السلة",
    emptyCart: "السلة فارغة",
    emptyCartMessage: "لم تقم بإضافة اي منتج الى سلة التسوق",
    month: "شهر",
    change: "تغيير",
    subscription: "اشتراك",
    yourCart: "سلتك",
    amount: "المبلغ",
    taxes: "الضرائب",
    deliveryCharges: "رسوم الحشن",
    couponApplied: "تم قبول القسيمة",
    applyCoupon: "ادخال القسمية",
    subTotal: "حاصل الجمع",
    changeCoupon: "تعديل القسيمة",
    removeCoupon: "حذف القسيمة",
    totalAmount: "المبلغ الإجمالي",
    enterCouponCode: "ادخل رمز القسيمة",
    couponCodeSuccess: "عظيم ! تم تطبيق رمز القسيمة",
    enterYourCouponCode: "ادخل رمز القسيمة الخاص بك",
    applyCouponButton: "تطبيق القسيمة",
    proceedButton: "إكمال",
    browseProducts: "~Browse Products",
  },
  confirmOrderScreen: {
    confirmOrder: "تم تأكيد الطلب",
    orderConfirmation: "تأكيد الطلب",
    orderConfirmationMessage: "هل انت متأكد من الطلب؟",
    emptyCart: "سلتك فارغة",
    emptyCartMessage: "لم تقم بعبئة السلة",
    quantity: "الكمية",
    month: "شهر",
    subscription: "اشتراك",
    orderSummary: "ملخص الطلب",
    yourCart: "سلتك",
    amount: "المبلغ",
    taxes: "الضرائب",
    deliveryCharges: "رسوم الشحن",
    couponApplied: "تم تأكيد الباقة",
    totalAmount: "اجمالي المبلغ",
    subTotal: "حاصل الجمع",
    shippingAddress: "عنوان الشحن",
    billingAddress: "عنوان الدفع",
    paymentOption: "خيارات الدفع",
    cod: "COD",
    credit_Debit_card: "البطاقة الإئتمانية",
    enterCouponCode: "ادخل رمز الباقة",
    couponCodeFailure: "خطأ في رمز الباقة",
    couponCodeSuccess: "تم ادخال رمز الباقة، بنجاح",
    enterYourCouponCode: "ادخل رمز الباقة",
    applyCouponButton: "ادخل الرمز",
    proceedButton: "استمر",
    changeCoupon: "تعديل القسيمة",
    removeCoupon: "حذف القسيمة",
    discount:"~Discount",
    selectAddress: "تحديد العنوان",
    name: "الاسم",
    flatNumber: "شقة / بيت / فلة",
    addressLine1: "السطر الأول من العنوان",
    addressLine2: "السطر الثاني من العنوان",
    city: "مدينة",
    state: "ولاية",
    country: "دولة",
    pinCode: "الرمز",
    phoneNumber: "رقم الهاتف",
    sameBillingAndShipping: "عنوان الدفع والشحن هما نفس العنوان",
    cashOnDelivery:"~Cash On Delivery",
    noPaymentRequired:"No Payment Required",
    placeOrder:"~Place Order",
    noExistAddress:"~No existing address is available right now.",
    addNewAddress: "اضافة عنوان جديد",
    continue: "متابعة",
    couponCodeCanntEmpty:"~Coupon code can't be empty"
  },
  orderPlace:{
    orderSuccessTitle:"~Order Placed Successfully!",
    orderSuccessMessage:"~Thank you so much for your order.",
    trackOrder:"~Track Order",
    GoHome:"~Go To Home"
  },
  transactionError:{
    transErrortitle:"~Oh Noes!, Transaction Failed",
    transErrorMessage:"~Your order was declined!",
    chnagePaymentMethod:"~Try Changing the Payment Method",
    cancelTransaction:"~Cancel transaction?"
  },
  newCardScreen: {
    newCard: "بطاقة جديدة",
    unableToOrder: "خطأ في الطلب",
    proceed: "استمر",
    orderConfirm: "تم تأكيد الطلب",
    thankyouText: "شكرا لتسوقك معنا",
    gotoMyOrders: "اذهب الى طلباتي",
    ohNoes: "Oh Noes!",
    orderDeclined: "لم يتم قبول طلبك",
    changePaymentMethod: "تغيير طريقة الدفع",
    cancelTransaction: "الغاء الدفع"
  },
  paymentInformationScreen: {
    paymentInformation: "معلومات الدفع",
    addNewCard: "اضافة بطاقة جديدة"
  },
  paymentCardScreen: {
    PAYMENT_INFORMATION: 'معلومات الدفع',
    CARD_NUMBER: 'رقم البطاقة',
    CRAD_HOLDER: 'اسم حامل البطاقة',
    EXPIRES: 'تاريخ الإنتهاء',
    SAVED_CARDS: 'حفظ البطاقات',
    CARD_NUMBER_TEXT: 'رقم البطاقة',
    CARD_HOLDER_TEXT: 'اسم حامل البطاقة',
    EXPIRY_TEXT: 'تاريخ الإنتهاء (MM/YY)',
    CVV: 'CVV'
  },
  stringConstants: {
    ACTIVE_NOT: 'تفعيل النتبيهات',
    YOU_WILL: 'سيتم إعلامك عندما يكون لدينا شيء جديد في المتجر وفي العروض والمبيعات!',
    NO_THANKS: 'لا، شكرا',
    FIND_THE: 'اعثر على افضل العروض حولك',
    HOMOEO_PHARMA: `Big Bargains requires location\naccess to show the best\nrecommendations from around you.`,
    PRODUCT_INFORMATION: 'Browse Medicines',
    MEDICINE_ORDER: 'Order Medicines',
    ENTER_AN_EMAIL: `Enter an Email address to get\n notified once the product is back\n in stock.`,
    NOTIFY_MSG: `You’ll now be notified once the\nproduct is back in stock.`
  },
  validationErrors:{
    nameRequired:"~Name is required.",
    onlyLetter:"~Only letters are allowed.",
    maxLetterLimit:"~Max 16 characters are allowed.",
    charAtLeast:"~Name should be atleast 3 characters.",
    emailEmpty:"~Please enter a valid email address.",
    emailRequired:"~Email is required.",
    minPassword:"~Minimum Password length is 8.",
    maxPassword:"~Maximum Password length is 16",
    passLetterSpecial:"~Password must contain atleast a capital letter, a lowercase letter, a number and a special character.",
    passwordRequired:"~Password is required.",
    passwordMatch:"~Passwords must match",
    confirmPassword:"~Confirm Password is required",
    onlyNumber:"~Only numbers are allowed.",
    otpRequired:"~OTP is required.",
    negativeNumNotAllow:"~Negative numbers are not allowed.",
    notDecimal:"~Number can't contain a decimal.",
    minFiveDigit:"~Minimum 5 digits are required.",
    maxFiveDigit:"~Maximum 5 digits are allowed.",
    minThree:"~Minimum 3 characters are required",
    canntBlank:"~Can't be left blank.",
    addressRequired:"~Address is required.",
     cityRequired:"~City is required.",
     stateRequired:"~State is required.",
     countryRequired:"~Country is required.",
     pinCode:"~Pincode is required.",
     phoneRequired:"~Phone Number is Required.",
     max20:"~Maximum 20 characters are allowed.",
     onlyLetter:"~Only letters are allowed."   ,
     mobileRequired:"~Mobile number is required.",
     min10Digits:"~Minimum 10 digits are required.",
     max10Digits:"~Maximum 10 digits are allowed.",
     min6Digits:"~Minimum 6 digits are required.",
     max6Digits:"~Maximum 6 digits are allowed.",
     AccountRequired:"~Account number is required.",
     max12Digits:"~Maximum 12 digits are allowed.",
     pinNotDecimal:"~Pincode can't contain a decimal.",
     phoneNotDecimal:"~Phone can't contain a decimal.",
     messageRequired:"~Message is required..",
     min30Characters:"~Minimum 30 characters are required.",
     purposeRequired:"~Purpose is required.",
     ratingRequired:"~Rating is required.",
     commentRequired:"~Comment is required.",
     specialCharNotAllow: "~Special characters are not allow"
 }
};