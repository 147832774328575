import React, { useEffect, useState, Fragment } from 'react';
import { Container } from 'reactstrap';
import parse from 'html-react-parser';

import { Header, Footer, PageLoadingBlock, ErrorOccuredBlock } from '../../components';
import commands from '../../commands';
import './css/index.scoped.css';
import { Link } from 'react-router-dom';

function Market(props) {
  const [content, setContent] = useState();
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false)
  const [errorData, setErrorData] = useState(null)
  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoading(true)
    if (content === undefined) {
      commands.pages
        .getMarket()
        .then((res) => {
          setContent(parse(res.data.market_details.content));
          setTitle(res.data.market_details.title)
          setLoading(false)
        })
        .catch((err) => {
          console.error(err);
          if (err.status === 404) {
            setErrorData({
              title: 'Oh Noes!, 404 Not Found',
              message: 'The product you are looking for is not available!',
              buttonText: 'Go to Home',
              onButtonPress: () => props.history.push('/'),
            });
          } else {
            setErrorData({
              title: 'Oh Noes!, Error Occured',
              message: 'No Content Available',
              buttonText: 'Retry',
              onButtonPress: () => {
                setLoading(true);
                setContent();
                setLoader(true);
                getData()
              },
            });
          }
          setContent(<div>No Content Available</div>);
          setLoading(false)
        });
    }
    setLoading(false)
  }

  return (
    <div>
      <Header onProps={props} />
      <section className="yt-about-us-pg">
        <Container>
          <div className="yt-cm-mobile-bread">
            <div className="pageroute profile-pg-breadcrumbs mt-4">
              <Link to="/">
                <span
                  className="cart-pg-home w3-hover-opacity"
                  style={{ cursor: 'default' }}
                >
                  Home
            </span>
              </Link>
              {' > '}
              <span className="">Market</span>
            </div>
            <h2 className="yt-profile-mb-ttl profile-pg-title">{title}</h2>
          </div>
          {!content && (
            <Fragment>
              {errorData && (
                <ErrorOccuredBlock />
              )}
              {!loading && (
                <PageLoadingBlock
                  title={'Please wait...'}
                  message={'Fetching Markets'}
                  buttonText={'Go to Home'}
                  onButtonPress={() => props.history.push('/')}
                />
              )}
            </Fragment>
          )}
          {content && (
            <div className="cardcontainer yt-main-wrapper rounded-lg bg-white">
              <div className="customcard yt-inner-wrap p-4 ">{content}</div>
            </div>
          )}
        </Container>
      </section>
      <div className="mt-5" />
      <Footer />
    </div>
  );
}

export default Market;
