import React from "react";
import parse from "html-react-parser";
import commands from "../../commands";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import _ from "lodash";
import "./css/index.scoped.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function DoubleOfferBanner() {
  const mybanner = commands.cache.getWebPositionedBanners({ position: 2 });
  const sortedPosition = _.sortBy(mybanner[0]?.images, ["Position"]);

  const history = useHistory();
  return (
    <section className="yt-double-offer-component">
      <Row className="yt-cm-db-row">
        <Col
          sm={12}
          sm={6}
          lg={6}
          className={
            sortedPosition[0]?.url
              ? "yt-cm-col right hoverMe"
              : "yt-cm-col right"
          }
          onClick={() => {
            sortedPosition[0]?.url &&
              (window.location.href = sortedPosition[0]?.url);
          }}
        >
          <div className="yt-doc-inner mb-4 mb-lg-0 ">
            <LazyLoadImage
              className="img-fluid three"
              alt="bigbargains"
              effect="blur"
              width="100%"
              src={
                sortedPosition[0]?.image
                  ? sortedPosition[0]?.original_image
                  : require("./images/banner-1.png")
              }
            />
            {/* <div className="yt-doc-box">
              <h1 className="yt-doc-tag yt-small-tag wrapMyWord">
                {sortedPosition[0]?.title}
              </h1>
              <div className="yt-doc-price-tagline wrapMyWord">
                {sortedPosition[0]?.subtitle}
              </div>
              <Button
                color=" yt-doc-btn py-3"
                onClick={() => (window.location.href = sortedPosition[0]?.url)}
              >
                Shop Now
              </Button>
            </div> */}
          </div>
        </Col>
        <Col
          sm={12}
          sm={6}
          lg={6}
          className={
            sortedPosition[1]?.url ? "yt-cm-col left hoverMe" : "yt-cm-col left"
          }
          onClick={() => {
            sortedPosition[1]?.url &&
              (window.location.href = sortedPosition[1]?.url);
          }}
        >
          <div className="yt-doc-inner">
            <img
              src={
                sortedPosition[0]?.image
                  ? sortedPosition[1]?.original_image
                  : require("./images/banner-2.png")
              }
              className="img-fluid three"
              alt="bigbargains"
            />
            {/* <div className="yt-doc-box">
              <div className="yt-doc-tag yt-small-tag wrapMyWord">
                {sortedPosition[1]?.title}
              </div>
              <div className="yt-doc-price-tagline wrapMyWord">
                {sortedPosition[1]?.subtitle}
              </div>
              <Button
                color=" yt-doc-btn py-3"
                onClick={() => (window.location.href = sortedPosition[1]?.url)}
              >
                Shop Now
              </Button>
            </div> */}
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default DoubleOfferBanner;
