import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { BsChevronRight } from "react-icons/bs";
import moment from "moment";
import capitalize from "lodash/capitalize";
import config from "../../../config";
import { AllModal } from "../../../components";
import lang from "../../../language";
import "./css/index.scoped.css";
import commands from "../../../commands";

function OrderDetailsPage(props) {
  const orederDetailLang = new lang("myOrderDetailScreen");
  const myconfig = config();
  const [ShowCOModal, settt] = useState(false);
  const openCancelOrderModal = () => settt(!ShowCOModal);
  const [ShowPRModal, pRsettt] = useState(false);
  const openProductRatingModal = () => pRsettt(!ShowPRModal);
  const history = useHistory();
  const [isReturned, setIsReturned] = useState(false);
  const { order, orderItem, trackingDetails, refetchData } = props; //.tracking_detail
  const [shippingAddress, setShippingAddress] = useState();
  const [returnReason, setReturnReason] = useState("");
  useEffect(() => {
    if (!orderItem) {
      routeToProfile();
    } else {
      let localAddressIndex = order.delivery_addresses.findIndex(
        (item) =>
          item.address_for === "shipping" ||
          item.address_for === "billing and shipping"
      );
      if (localAddressIndex >= 0) {
        setShippingAddress(order.delivery_addresses[localAddressIndex]);
      }
    }
  }, [props]);

  const routeToProfile = () => {
    let path = "/profile/myorder";
    history.push(path);
  };

  function getImageUrl() {
    if (
      orderItem.product_variant &&
      orderItem.product_variant.images.length > 0
    ) {
      return orderItem.product_variant.images[0].image;
    } else {
      return orderItem.product_image;
    }
  }
  let orderDataByParams = history.location.pathname.split("/");
  async function confirmReturn() {
    let orderData = history.location.pathname.split("/");
    const myResponse = await commands.products.returnProduct(
      orderData[4],
      orderData[3],
      returnReason
    );
    if (myResponse) {
      history.push("/returnPlaced");
    }
    console.log("MYRETURNREASON", myResponse);
    // orderData.length && console.log("MYRETURNREASON",orderData[4],orderData[3]);
  }
  function getAddressString() {
    if (shippingAddress) {
      return `${shippingAddress.address}, ${shippingAddress.address_line_2}, ${
        shippingAddress.city
      }, ${shippingAddress.state}, ${shippingAddress.zip_code}`;
    }
    return " 503 Branson Turnpike Suite 127, Lawrenceport, Florida 112202";
  }

  function getProductPrice() {
    if (orderItem) {
      return orderItem.product_variant
        ? order.product_variant_on_sale
          ? orderItem.product_variant_price
          : orderItem.product_variant_price
        : order.product_on_sale
        ? orderItem.product_product_sale_price
        : orderItem.product_price;
    }
    return "";
  }

  const getLocalDate = ({ date, toFormat }) => {
    let m = moment
      .utc(date)
      .local()
      .subtract("minutes", 5)
      .utcOffset(+4)
      .format(toFormat);
    return m;
  };

  const reviewData = {
    orderItemId: orderItem.id /*, orderId: order.id, reviewId*/,
    canReview: true,
  };

  const data = {
    currency: myconfig.currency,
    totalAmount: orderItem?.total_price,
    /*
    subTotal: cart.is_default_currency ? cart.sub_total : cart.sub_total_with_currency?.split(" ")[1],
    deliveryCharges: cart.is_default_currency ? cart.shipping_charges?.shipping_total : cart.shipping_total_with_currency?.split(" ")[1],
    tax: cart.is_default_currency ? cart.total_tax : cart.total_tax_with_currency?.split(" ")[1],
    discount: cart.is_default_currency ? cart.applied_discount : cart.applied_discount_with_currency?.split(" ")[1]
  */
  };
  return (
    <Container>
      {ShowCOModal ? <AllModal modalName="CancelOrder" /> : ""}
      <AllModal
        modalName="Productrate"
        reviewData={reviewData}
        isOpen={ShowPRModal}
        toggle={() => pRsettt(!ShowPRModal)}
        onSuccess={() => {
          refetchData();
        }}
      />
      <Row>
        <Col md={12}>
          <div className="pageroute hc-breadcrumbs my-3">
            <Link to="/">
              <span className="hc-home" style={{ cursor: "default" }}>
                Home {">"}
              </span>
            </Link>
            <Link to="/profile">
              <span className="hc-mid" style={{ cursor: "default" }}>
                {" "}
                Profile {">"}
              </span>{" "}
            </Link>
            <Link to="/profile/myorder">
              <span className="hc-mid" style={{ cursor: "default" }}>
                {" "}
                My Orders {">"}
              </span>{" "}
            </Link>
            <span
              className={isReturned ? "active-order-breadcrumbs" : "hc-current"}
              onClick={() => setIsReturned(false)}
            >
              Order Details
            </span>
            {isReturned && (
              <span className="hc-current"> {">"} Return Order</span>
            )}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} lg={12} className="px-3 col-xxl-9">
          <div className="od-cm-col-pad">
            <div className="hc-beckfrom-ct-page d-flex align-items-center hc-mb-30">
              <span
                className="w3-ripple"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  routeToProfile();
                }}
              >
                <FaLongArrowAltLeft className="hcp-back-arrow" />{" "}
                <span className="pl-2 hc-back-tag">
                  {isReturned ? "Return Order" : "Order Details"}
                </span>
              </span>
            </div>
            <div className="order-details-main-wrapper bg-white radius-10 mt-3 hc-mb-80">
              <div className="d-flex flex-wrap justify-content-between yt-sp-my-order-tdet-wrap">
                <div className="d-flex align-items-center flex-wrap sinlge-mo-dt-wrap ">
                  <div className="order-number-wrap">
                    <span className="order-tag">
                      {orederDetailLang.get("orderNumber", "Order Number")}:{" "}
                    </span>
                    <span className="order-tag-val">{order?.order_number}</span>
                  </div>
                  <div className="order-bdr-between" />
                  <div className="order-date-wrap">
                    <span className="order-tag">
                      {orederDetailLang.get("orderedOn", "Ordered on")}:{" "}
                    </span>
                    <span className="order-tag-val">{order?.order_date}</span>
                  </div>
                  <div className="order-bdr-between" />

                  <div className="order-number-wrap">
                    <span className="order-tag">
                      {order?.order_items && (
                        <>
                          ProductSku :
                          <span className="order-tag-val">
                            {order?.order_items.map((d)=>{
                              if(d.id == orderDataByParams[4]){
                               return (<span>{d.system_sku}</span>)
                              }
                            })
                            }
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="order-review text-right">
                  {!trackingDetails?.order_item_detail?.is_review_present &&
                    ["delivered", "returned"].includes(
                      trackingDetails?.tracking_detail[0]?.status?.toLowerCase()
                    ) && (
                      <Button
                        color="link order-write-review px-0"
                        onClick={() => pRsettt(true)}
                      >
                        {orederDetailLang.get("writeAReview", "Write a Review")}
                      </Button>
                    )}
                </div>
              </div>
              <div className="py-3 d-flex align-items-center mb-3 od-single-prd-details-wrap">
                <span
                  className="w3-ripple d-flex"
                  onClick={() =>
                    history.push(
                      "/shop/" + trackingDetails?.order_item_detail?.product.id
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="od-product-img p-1 d-flex align-items-center justify-content-center">
                    <img
                      src={
                        orderItem.product_images
                          ? orderItem.product_images[0]?.image
                          : null
                      }
                      className="img-fluid"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="order-product-info ">
                      <h2 className="pp-order-product-ttl mt-0 text-capitalize">
                        {orderItem.product_name}
                      </h2>

                      {/* <div className="product-id">
                        Product Code: 
                        {orderItem?.id}
                      </div> */}
                      <div className="order-prodict-type-container">
                        {orderItem.product_variant ? (
                          <Table
                            className="mb-0 order-prodict-type d-block"
                            borderless
                          >
                            {/*
                        <thead>
                          <tr>
                            {orderItem.product_variant.product_variant_properties.map((value, idx) => (<th>{value.variant_name}</th>))}
                          </tr>
                        </thead>
                        */}

                            <tbody>
                              <tr>
                                {orderItem.product_variant.product_variant_properties.map(
                                  (value, idx) => (
                                    <td>{value.property_name}</td>
                                  )
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        ) : (
                          <div />
                        )}
                      </div>
                      <ul className="p-0 order-ul-list-none mb-0 mt-2 d-flex flex-wrap align-items-center">
                        <li className="op-order-product-price pr-4">
                          <span className="order-product-price">
                            {myconfig.currency}{" "}
                            {parseFloat(data?.totalAmount || 0).toFixed(2)}
                          </span>
                        </li>
                        <li>
                          <span className="order-tracing-details">
                            {orederDetailLang.get("trackingId", "Tracking ID")}:{" "}
                            <span className="order-track-id">
                              {
                                trackingDetails?.tracking_detail[0]
                                  .tracking_number
                              }
                            </span>{" "}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
                <div>
                  <div className="order-product-quanity text-center text-sm-right">
                    <ul className="p-0 order-ul-list-none m-0 ">
                      <li className="op-order-quantity mb-3">
                        {orederDetailLang.get("quantity", "Quantity")}:{" "}
                        <span className="ord-product-quantity">
                          {orderItem.quantity}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="order-details-status on-the-way">
                    {capitalize(trackingDetails?.tracking_detail[0].status)}
                  </div>
                </div>
              </div>
              {isReturned ? (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="order-details-status-bar py-3 my-3">
                        <h2 className="order-details-sub-title">
                          Why are you returning this ?
                        </h2>
                        <Form>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="product-review-description"
                              id="product-review-description"
                              placeholder="Write Reson Of Return"
                              className="p-3"
                              value={returnReason}
                              onChange={(e) => setReturnReason(e.target.value)}
                            />
                          </FormGroup>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={12}
                      className="pb-4"
                      style={{
                        borderBottom: "2px solid #dbdbdb",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="order-details-contact">
                          <h2 className="order-details-sub-title">
                            Confirm your return
                          </h2>
                          <p className="order-details-address-details">
                            Your product will be picked up by a courier service.
                            Please return the product and packaging in its
                            original conditions by courier service.
                          </p>
                        </div>
                      </div>
                      <div className="return-show-slot">
                        <div className="order-details-contact">
                          <span className="order-details-phone myBold500">
                            Date & Time
                          </span>
                          <p className="order-details-address-details">
                            Monday, 20th May 20 | 07:00 - 12:00
                          </p>
                        </div>
                        <div className="order-details-contact">
                          <span className="order-details-phone myBold500">
                            Pickup Address
                          </span>
                          <p className="order-details-address-details">
                            {getAddressString()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="order-details-status-bar py-3 my-3 set-no-border">
                        <h2 className="order-details-sub-title">
                          Total Refund
                        </h2>
                        <p className="order-details-address-details ">
                          {myconfig.currency}{" "}
                          {parseFloat(data?.totalAmount || 0).toFixed(2)}
                        </p>
                        <Button
                          className="btn-return-order py-3 px-3"
                          onClick={() => confirmReturn()}
                        >
                          Confirm Your Return
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="order-details-status-bar py-3 my-3">
                        <h2 className="order-details-sub-title">
                          {orederDetailLang.get("orderStatus", "Order Status")}
                        </h2>
                        <ul className="pl-2 order-ul-list-none mb-0 ml-3 order-details-status-wrap">
                          {trackingDetails?.tracking_detail.map(
                            (item, index) => (
                              <div>
                                <li>
                                  <img
                                    alt="status check"
                                    src={require("./images/statuss-check.png")}
                                    className="order-details-status-icn"
                                  />
                                  <div className="order-step-1 order-st-otw">
                                    <h4 className="d-flex align-items-center">
                                      {capitalize(item.status) + " "}
                                      <span className="order-status-date">
                                        {item.order_date}
                                      </span>
                                    </h4>
                                    <p className="order-details-message">
                                      {item.msg},{" "}
                                      {/* {getLocalDate({
                                  date: item.order_datetime,
                                  toFormat: 'ddd, Do MMMM YYYY - hh:mm A',
                                })} */}
                                      {item?.order_datetime}
                                    </p>
                                  </div>
                                </li>
                              </div>
                            )
                          )}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="order-details-shipping-barmy-3">
                        <h2 className="order-details-sub-title">
                          {trackingDetails?.tracking_detail[0]?.status ==
                          "returned"
                            ? "Your product will be picked up by a courier service. Please return the product and packaging in its original conditions by courier service."
                            : "Shipping Address"}
                        </h2>
                        <div className="order-shipping-address-wrap">
                          <h2 className="order-details-address-type">
                            {trackingDetails?.tracking_detail[0]?.status ==
                            "returned" ? (
                              "Pickup Address"
                            ) : (
                              <>{capitalize(shippingAddress?.name)}</>
                            )}
                          </h2>
                          <p className="order-details-address-details">
                            {getAddressString()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div
                        className="d-flex align-items-center justify-content-between pb-4"
                        style={{
                          borderBottom:
                            trackingDetails?.tracking_detail[0]?.status ==
                              "delivered" && "2px solid #dbdbdb",
                        }}
                      >
                        <div className="order-details-contact">
                          {orederDetailLang.get("phoneNumber", "Phone Number")}:{" "}
                          <span className="order-details-phone">
                            {shippingAddress?.phone_number}
                          </span>
                        </div>
                        {trackingDetails?.tracking_detail[0]?.status ==
                          "returned" && (
                          <Button
                            className="btn-return-order py-3 px-3"
                            onClick={() => history.push("/shop")}
                          >
                            Continue Shopping
                          </Button>
                        )}
                        <div className="order-cancel-wrap text-right" />
                      </div>
                    </Col>
                  </Row>
                  {trackingDetails?.tracking_detail[0].status ==
                    "delivered" && (
                    <Row>
                      <Col md={12}>
                        <div className="order-details-shipping-barmy-3 pt-4">
                          <h2 className="order-details-sub-title">
                            Need help with your item ?
                          </h2>
                          <div
                            className="order-shipping-address-wrap d-flex"
                            style={{ cursor: "pointer" }}
                            onClick={() => setIsReturned(true)}
                          >
                            <div>
                              <h2 className="order-details-return-type">
                                Return Product
                              </h2>
                              <p className="order-details-address-details">
                                Eligible through 25th May 20
                              </p>
                            </div>
                            <div className="return-chevron">
                              <BsChevronRight />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderDetailsPage;
